var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "valid-marks-modal",
    attrs: {
      size: "lg",
      variant: "primary",
      static: "",
      centered: "",
      "hide-footer": "",
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [_vm._v(" Valid Marks ")]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "modal-body",
                staticStyle: { padding: "0px 10px", "overflow-x": "hidden" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "row pb-3",
                    staticStyle: {
                      position: "sticky",
                      top: "0",
                      background: "white",
                      "z-index": "1",
                    },
                  },
                  [
                    _c("div", { staticClass: "col-6" }, [
                      _c(
                        "div",
                        { staticClass: "kt-input-icon kt-input-icon--left" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filter,
                                expression: "filter",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: "Filter..." },
                            domProps: { value: _vm.filter },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.filter = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-input-icon__icon kt-input-icon__icon--left",
                            },
                            [
                              _c("span", [
                                _c("i", { staticClass: "la la-search" }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          "Select a grade below to apply the mark to the student"
                        ),
                      ]),
                    ]),
                    _c("div", { staticClass: "col-6" }, [
                      _c("div", { staticClass: "form-group pull-right" }, [
                        _c("div", { staticClass: "kt-checkbox-inline" }, [
                          _c("label", { staticClass: "kt-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.passing,
                                  expression: "passing",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.passing)
                                  ? _vm._i(_vm.passing, null) > -1
                                  : _vm.passing,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.passing,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.passing = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.passing = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.passing = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" Pass "),
                            _c("span"),
                          ]),
                          _c("label", { staticClass: "kt-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.failing,
                                  expression: "failing",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.failing)
                                  ? _vm._i(_vm.failing, null) > -1
                                  : _vm.failing,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.failing,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.failing = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.failing = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.failing = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" Fail "),
                            _c("span"),
                          ]),
                          _c("label", { staticClass: "kt-checkbox" }, [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.special,
                                  expression: "special",
                                },
                              ],
                              attrs: { type: "checkbox" },
                              domProps: {
                                checked: Array.isArray(_vm.special)
                                  ? _vm._i(_vm.special, null) > -1
                                  : _vm.special,
                              },
                              on: {
                                change: function ($event) {
                                  var $$a = _vm.special,
                                    $$el = $event.target,
                                    $$c = $$el.checked ? true : false
                                  if (Array.isArray($$a)) {
                                    var $$v = null,
                                      $$i = _vm._i($$a, $$v)
                                    if ($$el.checked) {
                                      $$i < 0 &&
                                        (_vm.special = $$a.concat([$$v]))
                                    } else {
                                      $$i > -1 &&
                                        (_vm.special = $$a
                                          .slice(0, $$i)
                                          .concat($$a.slice($$i + 1)))
                                    }
                                  } else {
                                    _vm.special = $$c
                                  }
                                },
                              },
                            }),
                            _vm._v(" Special "),
                            _c("span"),
                          ]),
                        ]),
                        _c(
                          "span",
                          { staticClass: "form-text text-muted pull-right" },
                          [_vm._v("Filter by type")]
                        ),
                      ]),
                    ]),
                  ]
                ),
                _c(
                  "div",
                  {
                    staticClass: "kt-widget28 pt-3",
                    staticStyle: { width: "100%" },
                  },
                  [
                    _c(
                      "div",
                      {
                        staticClass: "kt-widget28__wrapper kt-portlet__space-x",
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass: "tab-pane active row",
                            staticStyle: { display: "flex" },
                          },
                          _vm._l(_vm.validMarks, function (g) {
                            return _c(
                              "div",
                              {
                                key: "grd" + g.markingPeriodValidMarkId,
                                staticClass: "col-lg-3",
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "kt-widget28__tab-items" },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass: "kt-widget28__tab-item",
                                        staticStyle: { cursor: "pointer" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.chooseMark(g)
                                          },
                                        },
                                      },
                                      [
                                        _c("span", [
                                          _c("span", [
                                            _c(
                                              "div",
                                              {
                                                staticStyle: {
                                                  margin: "10px 0",
                                                },
                                              },
                                              [
                                                _c("span", {
                                                  staticClass: "colorSwatch",
                                                  class: {
                                                    Green: g.passing,
                                                    Red: g.passing === false,
                                                    Gray: g.passing === null,
                                                  },
                                                }),
                                                _vm._v(
                                                  " " + _vm._s(g.mark) + " "
                                                ),
                                                g.numericEquivalent
                                                  ? _c(
                                                      "code",
                                                      {
                                                        staticStyle: {
                                                          margin: "0 10px",
                                                          color: "#0571f7",
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          _vm._s(
                                                            g.numericEquivalent
                                                          ) + " %"
                                                        ),
                                                      ]
                                                    )
                                                  : _vm._e(),
                                                _c("br"),
                                                _c(
                                                  "small",
                                                  { staticClass: "text-muted" },
                                                  [
                                                    _vm._v(
                                                      _vm._s(g.markDescription)
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                              ]
                            )
                          }),
                          0
                        ),
                      ]
                    ),
                  ]
                ),
              ]
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }