var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.student
        ? _c(
            "div",
            {
              key: `${_vm.student.courseSectionStudentId}_${_vm.key}`,
              staticClass: "d-flex p-2",
            },
            [
              _c(
                "div",
                {
                  staticClass: "col-12 align-self-stretch flex-fill stars-box",
                },
                [
                  _c("div", { staticClass: "d-flex" }, [
                    _c("div", { staticClass: "p-2 mr-auto" }, [
                      _c(
                        "div",
                        {
                          staticClass: "kt-widget5 p-0",
                          staticStyle: {
                            "max-height": "170px",
                            "min-width": "220px",
                          },
                        },
                        [
                          _c(
                            "div",
                            { staticClass: "kt-widget5__item pb-0 mb-0" },
                            [
                              _c(
                                "div",
                                { staticClass: "kt-widget5__content" },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "kt-widget5__pic pr-0",
                                      staticStyle: {
                                        width: "50px",
                                        "max-height": "49px",
                                        overflow: "hidden",
                                        margin: "0",
                                      },
                                    },
                                    [
                                      _vm.encryptionEnabled
                                        ? _c("StudentAvatar", {
                                            staticStyle: {
                                              width: "50px",
                                              height: "50px",
                                              "padding-right": "10px",
                                            },
                                            attrs: { student: _vm.student },
                                          })
                                        : _c(
                                            "UserAvatar",
                                            {
                                              staticClass: "kt-widget7__img",
                                              staticStyle: {
                                                "max-height": "38px",
                                                "max-width": "38px",
                                                margin: "6px",
                                              },
                                              attrs: {
                                                "avatar-user": _vm.student,
                                              },
                                            },
                                            [
                                              _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "kt-media kt-media--sm",
                                                },
                                                [
                                                  _c(
                                                    "a",
                                                    {
                                                      staticClass:
                                                        "kt-media kt-media--lg kt-media--success",
                                                      staticStyle: {
                                                        "max-width": "38px",
                                                        "margin-left": "6px",
                                                        "margin-right": "6px",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticStyle: {
                                                            height: "1.75rem",
                                                            "font-size":
                                                              "0.75rem",
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            " " +
                                                              _vm._s(
                                                                _vm.initials
                                                              ) +
                                                              " "
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass: "kt-widget5__section",
                                      staticStyle: {
                                        "white-space": "nowrap",
                                        width: "165px",
                                      },
                                    },
                                    [
                                      _c(
                                        "a",
                                        {
                                          staticClass:
                                            "kt-widget5__title pb-0 w-100",
                                          staticStyle: {
                                            "white-space": "nowrap",
                                          },
                                          attrs: { href: "#" },
                                          on: {
                                            click: function ($event) {
                                              $event.stopPropagation()
                                              $event.preventDefault()
                                              return _vm.openPanelForStudent(
                                                _vm.student
                                              )
                                            },
                                          },
                                        },
                                        [
                                          !_vm.clientSideEncryptionEnabled
                                            ? [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.student.lastName
                                                    ) +
                                                    ", " +
                                                    _vm._s(
                                                      _vm.student.firstName
                                                    ) +
                                                    " "
                                                ),
                                              ]
                                            : [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(
                                                      _vm.student
                                                        .maskedStudentName
                                                    ) +
                                                    " "
                                                ),
                                              ],
                                        ],
                                        2
                                      ),
                                      _vm.student.courseSection
                                        ? _c(
                                            "p",
                                            {
                                              staticClass:
                                                "kt-widget5__desc p-0",
                                            },
                                            [
                                              _vm.student.courseSection
                                                .courseSectionIcon
                                                ? _c("span", [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.student
                                                            .courseSection
                                                            .courseSectionIcon
                                                        ) +
                                                        " "
                                                    ),
                                                  ])
                                                : _vm._e(),
                                              _vm._v(
                                                " " +
                                                  _vm._s(
                                                    _vm.student.courseSection
                                                      .courseSectionTitle
                                                  ) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ]
                              ),
                              _c(
                                "div",
                                { staticClass: "kt-widget5__content" },
                                [
                                  _c(
                                    "div",
                                    { staticClass: "kt-widget5__stats" },
                                    [
                                      _c("span", {
                                        staticClass: "kt-widget5__number",
                                      }),
                                      _c(
                                        "span",
                                        { staticClass: "kt-widget5__sales" },
                                        [_vm._v(_vm._s(_vm.student.homeRoom))]
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      ),
                    ]),
                    _vm.shouldShowFinal
                      ? _c("div", { staticClass: "pt-3" }, [
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-switch kt-switch--outline kt-switch--icon kt-switch--success kt-switch--sm",
                            },
                            [
                              _c("label", [
                                _c(
                                  "label",
                                  { staticClass: "p-2 kt-font-bold font" },
                                  [_vm._v("Final Grade")]
                                ),
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model",
                                      value: _vm.student.grade.isFinal,
                                      expression: "student.grade.isFinal",
                                    },
                                  ],
                                  attrs: {
                                    type: "checkbox",
                                    name: "quick_panel_notifications_1",
                                    disabled: _vm.readOnly,
                                  },
                                  domProps: {
                                    checked: Array.isArray(
                                      _vm.student.grade.isFinal
                                    )
                                      ? _vm._i(
                                          _vm.student.grade.isFinal,
                                          null
                                        ) > -1
                                      : _vm.student.grade.isFinal,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        var $$a = _vm.student.grade.isFinal,
                                          $$el = $event.target,
                                          $$c = $$el.checked ? true : false
                                        if (Array.isArray($$a)) {
                                          var $$v = null,
                                            $$i = _vm._i($$a, $$v)
                                          if ($$el.checked) {
                                            $$i < 0 &&
                                              _vm.$set(
                                                _vm.student.grade,
                                                "isFinal",
                                                $$a.concat([$$v])
                                              )
                                          } else {
                                            $$i > -1 &&
                                              _vm.$set(
                                                _vm.student.grade,
                                                "isFinal",
                                                $$a
                                                  .slice(0, $$i)
                                                  .concat($$a.slice($$i + 1))
                                              )
                                          }
                                        } else {
                                          _vm.$set(
                                            _vm.student.grade,
                                            "isFinal",
                                            $$c
                                          )
                                        }
                                      },
                                      function ($event) {
                                        return _vm.keyUp($event, "isFinal")
                                      },
                                    ],
                                  },
                                }),
                                _c("span"),
                              ]),
                            ]
                          ),
                        ])
                      : _vm._e(),
                  ]),
                  _c(
                    "div",
                    {
                      staticClass: "d-flex p-0 col-12",
                      staticStyle: { "justify-content": "space-evenly" },
                    },
                    [
                      _c(
                        "div",
                        {
                          staticClass: "d-flex p-0 mt-3",
                          class: _vm.shouldShowExamGrade ? "col-6" : "col-12",
                          staticStyle: { "align-items": "center" },
                        },
                        [
                          _c("div", { staticClass: "p-2 col-8" }, [
                            _c(
                              "div",
                              {
                                staticClass: "form-group",
                                staticStyle: { "margin-bottom": "0" },
                              },
                              [
                                _c(
                                  "div",
                                  { staticClass: "input-group input-group-sm" },
                                  [
                                    _c("vue-typeahead-bootstrap", {
                                      directives: [
                                        {
                                          name: "b-tooltip",
                                          rawName:
                                            "v-b-tooltip.v-primary.hover",
                                          modifiers: {
                                            "v-primary": true,
                                            hover: true,
                                          },
                                        },
                                      ],
                                      ref: "overall_grade",
                                      attrs: {
                                        title:
                                          "Enter the student's overall grade here",
                                        data: _vm.courseValidMarks,
                                        "show-on-focus": true,
                                        value: _vm.student.grade.mark
                                          ? `${_vm.student.grade.mark}`
                                          : "",
                                        serializer: (m) => `${m.mark}`,
                                        disabled: _vm.readOnly,
                                        placeholder: "Overall Grade",
                                      },
                                      on: {
                                        keyup: function ($event) {
                                          return _vm.keyUp(
                                            $event,
                                            "overall_grade"
                                          )
                                        },
                                        hit: function ($event) {
                                          return _vm.keyUp(
                                            $event,
                                            "overall_grade"
                                          )
                                        },
                                      },
                                    }),
                                    _c(
                                      "div",
                                      {
                                        directives: [
                                          {
                                            name: "b-tooltip",
                                            rawName:
                                              "v-b-tooltip.v-primary.hover",
                                            modifiers: {
                                              "v-primary": true,
                                              hover: true,
                                            },
                                          },
                                        ],
                                        staticClass: "input-group-append",
                                        attrs: {
                                          title:
                                            "Click here to see all available marks",
                                        },
                                      },
                                      [
                                        _c(
                                          "span",
                                          {
                                            staticClass: "input-group-text",
                                            style: `cursor: ${
                                              !_vm.readOnly ? "pointer" : ""
                                            }`,
                                            on: {
                                              click: function ($event) {
                                                return _vm.validGradeModal(
                                                  "course"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm.student.grade.savingOverall ||
                                            _vm.student.grade.dirtyOverall
                                              ? _c("div", {
                                                  staticClass:
                                                    "kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info",
                                                  staticStyle: {
                                                    "margin-right": "18px",
                                                  },
                                                })
                                              : _vm.student.grade.saveStatus
                                              ? _c("i", {
                                                  staticClass:
                                                    "fa fa-times text-danger",
                                                  attrs: {
                                                    "aria-hidden": "true",
                                                  },
                                                })
                                              : _c("i", {
                                                  staticClass:
                                                    "fa fa-spell-check font",
                                                  class: {
                                                    Red:
                                                      _vm.student.grade
                                                        .passing == false,
                                                    Green:
                                                      _vm.student.grade
                                                        .passing == true,
                                                  },
                                                }),
                                          ]
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                          ]),
                          _c("div", { staticClass: "p-2 col-4 sg-abs-width" }, [
                            _c(
                              "span",
                              {
                                staticClass: "kt-font-bold font",
                                class: {
                                  Red: _vm.student.grade.passing == false,
                                  Green: _vm.student.grade.passing == true,
                                },
                              },
                              [_vm._v(" Overall Grade ")]
                            ),
                          ]),
                        ]
                      ),
                      _vm.shouldShowExamGrade
                        ? _c(
                            "div",
                            {
                              staticClass: "d-flex col-6 mt-3",
                              staticStyle: { "align-items": "center" },
                            },
                            [
                              _c("div", { staticClass: "p-2 col-8" }, [
                                _c(
                                  "div",
                                  {
                                    staticClass: "form-group",
                                    staticStyle: { "margin-bottom": "0" },
                                  },
                                  [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "input-group input-group-sm",
                                      },
                                      [
                                        _c("vue-typeahead-bootstrap", {
                                          directives: [
                                            {
                                              name: "b-tooltip",
                                              rawName:
                                                "v-b-tooltip.v-primary.hover",
                                              modifiers: {
                                                "v-primary": true,
                                                hover: true,
                                              },
                                            },
                                          ],
                                          ref: "exam_grade",
                                          attrs: {
                                            title:
                                              "Enter the student's exam grade here",
                                            data: _vm.examValidMarks,
                                            "show-on-focus": true,
                                            value: _vm.student.grade.examMark
                                              ? `${_vm.student.grade.examMark}`
                                              : "",
                                            serializer: (m) => `${m.mark}`,
                                            disabled: _vm.readOnly,
                                            placeholder: "Exam Grade",
                                          },
                                          on: {
                                            keyup: function ($event) {
                                              return _vm.keyUp(
                                                $event,
                                                "exam_grade"
                                              )
                                            },
                                            hit: function ($event) {
                                              return _vm.keyUp(
                                                $event,
                                                "exam_grade"
                                              )
                                            },
                                          },
                                        }),
                                        _c(
                                          "div",
                                          {
                                            directives: [
                                              {
                                                name: "b-tooltip",
                                                rawName:
                                                  "v-b-tooltip.v-primary.hover",
                                                modifiers: {
                                                  "v-primary": true,
                                                  hover: true,
                                                },
                                              },
                                            ],
                                            staticClass: "input-group-append",
                                            attrs: {
                                              title:
                                                "Click here to see all available marks",
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass: "input-group-text",
                                                staticStyle: {
                                                  cursor: "pointer",
                                                },
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.validGradeModal(
                                                      "exam"
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _vm.student.grade.savingExam ||
                                                _vm.student.grade.dirtyExam
                                                  ? _c("div", {
                                                      staticClass:
                                                        "kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info",
                                                      staticStyle: {
                                                        "margin-right": "18px",
                                                      },
                                                    })
                                                  : _vm.student.grade.saveStatus
                                                  ? _c("i", {
                                                      staticClass:
                                                        "fa fa-times text-danger",
                                                      attrs: {
                                                        "aria-hidden": "true",
                                                      },
                                                    })
                                                  : _c("i", {
                                                      staticClass:
                                                        "fa fa-spell-check font",
                                                      class: {
                                                        Red:
                                                          _vm.student.grade
                                                            .passing == false,
                                                        Green:
                                                          _vm.student.grade
                                                            .passing == true,
                                                      },
                                                    }),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                              _c(
                                "div",
                                { staticClass: "p-2 col-4 sg-abs-width" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass: "kt-font-bold font",
                                      class: {
                                        Red: _vm.student.grade.passing == false,
                                        Green:
                                          _vm.student.grade.passing == true,
                                      },
                                    },
                                    [_vm._v(" Exam Grade ")]
                                  ),
                                ]
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                  _vm.shouldShowConduct
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex mt-3",
                          staticStyle: {
                            "align-items": "center",
                            width: "100%",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "p-2",
                              class: _vm.shouldShowExamGrade
                                ? "col-10"
                                : "col-8",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  staticStyle: { "margin-bottom": "0" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group input-group-sm",
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.student.grade.conduct,
                                              expression:
                                                "student.grade.conduct",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { disabled: _vm.readOnly },
                                          on: {
                                            input: function ($event) {
                                              return _vm.keyUp(
                                                $event,
                                                "conduct"
                                              )
                                            },
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.student.grade,
                                                "conduct",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(
                                          _vm.conducts,
                                          function (conduct) {
                                            return _c(
                                              "option",
                                              {
                                                key: conduct.shortName,
                                                domProps: {
                                                  value: conduct.shortName,
                                                  selected:
                                                    _vm.student.grade.conduct ==
                                                    conduct.shortName,
                                                },
                                              },
                                              [
                                                _vm._v(
                                                  " " +
                                                    _vm._s(conduct.shortName) +
                                                    " - " +
                                                    _vm._s(conduct.longName) +
                                                    " "
                                                ),
                                              ]
                                            )
                                          }
                                        ),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "p-2 sg-abs-width",
                              class: _vm.shouldShowExamGrade
                                ? "col-2"
                                : "col-4",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "kt-font-bold font",
                                  class: { Green: _vm.student.grade.conduct },
                                },
                                [_vm._v(" Conduct ")]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.shouldShowLevel
                    ? _c(
                        "div",
                        {
                          staticClass: "d-flex mt-3",
                          staticStyle: {
                            "align-items": "center",
                            width: "100%",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticClass: "p-2",
                              class: _vm.shouldShowExamGrade
                                ? "col-10"
                                : "col-8",
                            },
                            [
                              _c(
                                "div",
                                {
                                  staticClass: "form-group",
                                  staticStyle: { "margin-bottom": "0" },
                                },
                                [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group input-group-sm",
                                    },
                                    [
                                      _c(
                                        "select",
                                        {
                                          directives: [
                                            {
                                              name: "model",
                                              rawName: "v-model",
                                              value: _vm.student.grade.level,
                                              expression: "student.grade.level",
                                            },
                                          ],
                                          staticClass: "form-control",
                                          attrs: { disabled: _vm.readOnly },
                                          on: {
                                            input: function ($event) {
                                              return _vm.keyUp($event, "level")
                                            },
                                            change: function ($event) {
                                              var $$selectedVal =
                                                Array.prototype.filter
                                                  .call(
                                                    $event.target.options,
                                                    function (o) {
                                                      return o.selected
                                                    }
                                                  )
                                                  .map(function (o) {
                                                    var val =
                                                      "_value" in o
                                                        ? o._value
                                                        : o.value
                                                    return val
                                                  })
                                              _vm.$set(
                                                _vm.student.grade,
                                                "level",
                                                $event.target.multiple
                                                  ? $$selectedVal
                                                  : $$selectedVal[0]
                                              )
                                            },
                                          },
                                        },
                                        _vm._l(_vm.levels, function (level) {
                                          return _c(
                                            "option",
                                            {
                                              key: level.number,
                                              domProps: {
                                                value: level.number,
                                                selected:
                                                  _vm.student.grade.level ==
                                                  level.number,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                " " +
                                                  _vm._s(level.number) +
                                                  " - " +
                                                  _vm._s(level.longName) +
                                                  " "
                                              ),
                                            ]
                                          )
                                        }),
                                        0
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _c(
                            "div",
                            {
                              staticClass: "p-2 sg-abs-width",
                              class: _vm.shouldShowExamGrade
                                ? "col-2"
                                : "col-4",
                            },
                            [
                              _c(
                                "span",
                                {
                                  staticClass: "kt-font-bold font",
                                  class: { Green: _vm.student.grade.level },
                                },
                                [_vm._v(" Level ")]
                              ),
                            ]
                          ),
                        ]
                      )
                    : _vm._e(),
                  _vm.shouldShowComments
                    ? _c("h6", { staticClass: "mt-4" }, [_vm._v(" Comments ")])
                    : _vm._e(),
                  _vm.shouldShowComments
                    ? _c("div", { staticClass: "d-flex" }, [
                        _c(
                          "div",
                          {
                            staticClass: "p-2 mr-4 flex-fill",
                            staticStyle: { "margin-bottom": "-25px" },
                          },
                          [
                            _vm.shouldShowCommentOne
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group input-group-sm",
                                    },
                                    [
                                      _c("vue-typeahead-bootstrap", {
                                        ref: "comment1",
                                        attrs: {
                                          data: _vm.commentCodes,
                                          size: "sm",
                                          "show-on-focus": true,
                                          value: _vm.student.grade.comments[0]
                                            .commentCode
                                            ? `${_vm.student.grade.comments[0].commentCode}: ${_vm.student.grade.comments[0].commentDescription}`
                                            : "",
                                          serializer: (comment) =>
                                            `${comment.commentCode}: ${comment.commentDescription}`,
                                          disabled: _vm.readOnly,
                                          placeholder: "Comment Code 1",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.keyUp(
                                              $event,
                                              "comment1",
                                              0
                                            )
                                          },
                                          hit: function ($event) {
                                            return _vm.keyUp(
                                              $event,
                                              "comment1",
                                              0
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "input-group-append" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.v-primary.hover",
                                                  modifiers: {
                                                    "v-primary": true,
                                                    hover: true,
                                                  },
                                                },
                                              ],
                                              staticClass: "input-group-text",
                                              attrs: {
                                                title:
                                                  "Click here to see all comment codes",
                                              },
                                            },
                                            [
                                              _vm.student.grade.comments[0]
                                                .saving ||
                                              _vm.student.grade.comments[0]
                                                .dirty
                                                ? _c("div", {
                                                    staticClass:
                                                      "kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info",
                                                    staticStyle: {
                                                      "margin-right": "18px",
                                                    },
                                                  })
                                                : _vm.student.grade.comments[0]
                                                    .saveStatus
                                                ? _c("i", {
                                                    staticClass:
                                                      "fa fa-times text-danger",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  })
                                                : _c("i", {
                                                    staticClass:
                                                      "fa fa-comment font",
                                                    class: {
                                                      Blue: _vm.student.grade
                                                        .comments[0]
                                                        .commentCode,
                                                    },
                                                    style: `cursor: ${
                                                      !_vm.readOnly
                                                        ? "pointer"
                                                        : ""
                                                    }`,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.commentCodeModal(
                                                          0
                                                        )
                                                      },
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.shouldShowCommentTwo
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group input-group-sm",
                                    },
                                    [
                                      _c("vue-typeahead-bootstrap", {
                                        ref: "comment2",
                                        attrs: {
                                          data: _vm.commentCodes,
                                          size: "sm",
                                          "show-on-focus": true,
                                          value: _vm.student.grade.comments[1]
                                            .commentCode
                                            ? `${_vm.student.grade.comments[1].commentCode}: ${_vm.student.grade.comments[1].commentDescription}`
                                            : "",
                                          serializer: (comment) =>
                                            `${comment.commentCode}: ${comment.commentDescription}`,
                                          disabled: _vm.readOnly,
                                          placeholder: "Comment Code 2",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.keyUp(
                                              $event,
                                              "comment2",
                                              1
                                            )
                                          },
                                          hit: function ($event) {
                                            return _vm.keyUp(
                                              $event,
                                              "comment2",
                                              1
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "input-group-append" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.v-primary.hover",
                                                  modifiers: {
                                                    "v-primary": true,
                                                    hover: true,
                                                  },
                                                },
                                              ],
                                              staticClass: "input-group-text",
                                              attrs: {
                                                title:
                                                  "Click here to see all comment codes",
                                              },
                                            },
                                            [
                                              _vm.student.grade.comments[1]
                                                .saving ||
                                              _vm.student.grade.comments[1]
                                                .dirty
                                                ? _c("div", {
                                                    staticClass:
                                                      "kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info",
                                                    staticStyle: {
                                                      "margin-right": "18px",
                                                    },
                                                  })
                                                : _vm.student.grade.comments[1]
                                                    .saveStatus
                                                ? _c("i", {
                                                    staticClass:
                                                      "fa fa-times text-danger",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  })
                                                : _c("i", {
                                                    staticClass:
                                                      "fa fa-comment font",
                                                    class: {
                                                      Blue: _vm.student.grade
                                                        .comments[1]
                                                        .commentCode,
                                                    },
                                                    style: `cursor: ${
                                                      !_vm.readOnly
                                                        ? "pointer"
                                                        : ""
                                                    }`,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.commentCodeModal(
                                                          1
                                                        )
                                                      },
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm.shouldShowCommentThree
                              ? _c("div", { staticClass: "form-group" }, [
                                  _c(
                                    "div",
                                    {
                                      staticClass: "input-group input-group-sm",
                                    },
                                    [
                                      _c("vue-typeahead-bootstrap", {
                                        ref: "comment3",
                                        attrs: {
                                          data: _vm.commentCodes,
                                          size: "sm",
                                          "show-on-focus": true,
                                          value: _vm.student.grade.comments[2]
                                            .commentCode
                                            ? `${_vm.student.grade.comments[2].commentCode}: ${_vm.student.grade.comments[2].commentDescription}`
                                            : "",
                                          serializer: (comment) =>
                                            `${comment.commentCode}: ${comment.commentDescription}`,
                                          disabled: _vm.readOnly,
                                          placeholder: "Comment Code 3",
                                        },
                                        on: {
                                          keyup: function ($event) {
                                            return _vm.keyUp(
                                              $event,
                                              "comment3",
                                              2
                                            )
                                          },
                                          hit: function ($event) {
                                            return _vm.keyUp(
                                              $event,
                                              "comment3",
                                              2
                                            )
                                          },
                                        },
                                      }),
                                      _c(
                                        "div",
                                        { staticClass: "input-group-append" },
                                        [
                                          _c(
                                            "span",
                                            {
                                              directives: [
                                                {
                                                  name: "b-tooltip",
                                                  rawName:
                                                    "v-b-tooltip.v-primary.hover",
                                                  modifiers: {
                                                    "v-primary": true,
                                                    hover: true,
                                                  },
                                                },
                                              ],
                                              staticClass: "input-group-text",
                                              attrs: {
                                                title:
                                                  "Click here to see all comment codes",
                                              },
                                            },
                                            [
                                              _vm.student.grade.comments[2]
                                                .saving ||
                                              _vm.student.grade.comments[2]
                                                .dirty
                                                ? _c("div", {
                                                    staticClass:
                                                      "kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info",
                                                    staticStyle: {
                                                      "margin-right": "18px",
                                                    },
                                                  })
                                                : _vm.student.grade.comments[2]
                                                    .saveStatus
                                                ? _c("i", {
                                                    staticClass:
                                                      "fa fa-times text-danger",
                                                    attrs: {
                                                      "aria-hidden": "true",
                                                    },
                                                  })
                                                : _c("i", {
                                                    staticClass:
                                                      "fa fa-comment font",
                                                    class: {
                                                      Blue: _vm.student.grade
                                                        .comments[2]
                                                        .commentCode,
                                                    },
                                                    style: `cursor: ${
                                                      !_vm.readOnly
                                                        ? "pointer"
                                                        : ""
                                                    }`,
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.commentCodeModal(
                                                          2
                                                        )
                                                      },
                                                    },
                                                  }),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                          ]
                        ),
                      ])
                    : _vm._e(),
                  _vm.markingPeriod.narrative
                    ? _c("h6", { staticClass: "mt-4" }, [_vm._v(" Narrative ")])
                    : _vm._e(),
                  _vm.markingPeriod.narrative
                    ? _c("div", { staticClass: "d-flex" }, [
                        _c("div", { staticClass: "p-2 mr-4 flex-fill" }, [
                          _c("div", { staticClass: "form-group validated" }, [
                            _vm.student.grade.narrativeDirty
                              ? _c("div", {
                                  staticClass:
                                    "kt-spinner kt-spinner--v2 kt-spinner--md kt-spinner--info comment",
                                })
                              : _vm._e(),
                            _c("textarea", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.student.grade.narrativeComment,
                                  expression: "student.grade.narrativeComment",
                                },
                              ],
                              staticClass: "form-control",
                              class: {
                                "is-invalid":
                                  _vm.student.grade.narrativeSaveStatus,
                              },
                              attrs: {
                                disabled: _vm.readOnly,
                                placeholder: "Narrative comment",
                                rows: "2",
                              },
                              domProps: {
                                value: _vm.student.grade.narrativeComment,
                              },
                              on: {
                                keyup: function ($event) {
                                  return _vm.keyUp($event, "narrative")
                                },
                                input: function ($event) {
                                  if ($event.target.composing) return
                                  _vm.$set(
                                    _vm.student.grade,
                                    "narrativeComment",
                                    $event.target.value
                                  )
                                },
                              },
                            }),
                          ]),
                        ]),
                      ])
                    : _vm._e(),
                ]
              ),
              _c(
                "div",
                {
                  staticClass: "col-4 align-self-stretch flex-fill google-box",
                },
                [
                  _c(
                    "div",
                    { staticClass: "row" },
                    [
                      _vm.markingPeriod.cumulative &&
                      !Number.isNaN(_vm.student.cumulativeReportCardAverage)
                        ? _c("div", { staticClass: "col-12 p-2" }, [
                            _c("div", { staticClass: "kt-section" }, [
                              _c(
                                "div",
                                { staticClass: "kt-section__content" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "btn btn-sm kt-font-bolder scaleColorBadge",
                                      class: {
                                        "btn-success":
                                          _vm.student
                                            .cumulativeReportCardAverage >= 0.9,
                                        "btn-primary":
                                          _vm.student
                                            .cumulativeReportCardAverage >=
                                            0.7 &&
                                          _vm.student
                                            .cumulativeReportCardAverage < 0.9,
                                        "btn-warning":
                                          _vm.student
                                            .cumulativeReportCardAverage >=
                                            0.5 &&
                                          _vm.student
                                            .cumulativeReportCardAverage < 0.7,
                                        "btn-danger":
                                          _vm.student
                                            .cumulativeReportCardAverage < 0.5,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.percentage(
                                              _vm.student
                                                .cumulativeReportCardAverage
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "kt-section__desc" }, [
                                _vm._v(
                                  " Estimated Cumulative Report Card Average "
                                ),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.student.average &&
                      (_vm.scaledDisplayPreference === "All" ||
                        _vm.scaledDisplayPreference === "ShowPercentage")
                        ? _c("div", { staticClass: "col-6 p-2" }, [
                            _c("div", { staticClass: "kt-section" }, [
                              _c(
                                "div",
                                { staticClass: "kt-section__content" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "btn btn-sm kt-font-bolder scaleColorBadge",
                                      class: _vm.student.average.markingPeriod
                                        .scaled
                                        ? _vm.student.average.markingPeriod
                                            .scaled.color
                                        : "lightgrey",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openBreakdown("Overall")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.percentage(
                                              _vm.student.average.markingPeriod
                                                .average
                                            )
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "kt-section__desc" }, [
                                _vm._v(" Average "),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.student.average &&
                      (_vm.scaledDisplayPreference === "All" ||
                        _vm.scaledDisplayPreference === "ShowScale")
                        ? _c("div", { staticClass: "col-6 p-2" }, [
                            _c("div", { staticClass: "kt-section" }, [
                              _c(
                                "div",
                                { staticClass: "kt-section__content" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "btn btn-sm btn-icon btn-elevate btn-circle kt-font-bolder scaleColorBadge",
                                      class: _vm.student.average.markingPeriod
                                        .scaled
                                        ? _vm.student.average.markingPeriod
                                            .scaled.color
                                        : "lightgrey",
                                      on: {
                                        click: function ($event) {
                                          return _vm.openBreakdown("Overall")
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.average.markingPeriod
                                              .scaled
                                              ? _vm.student.average
                                                  .markingPeriod.scaled.mark
                                              : "N/A"
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "kt-section__desc" }, [
                                _vm._v(" Scaled "),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.scaledDisplayPreference !== "All"
                        ? _c("div", { staticClass: "col-6 p-2" })
                        : _vm._e(),
                      _vm.student.courseAttendance
                        ? _c("div", { staticClass: "col-6 p-2" }, [
                            _c("div", { staticClass: "kt-section" }, [
                              _c(
                                "div",
                                { staticClass: "kt-section__content" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "btn btn-sm kt-font-bolder scaleColorBadge",
                                      class: {
                                        "btn-success":
                                          _vm.student.courseAttendance
                                            .percentPresent >= 90,
                                        "btn-warning":
                                          _vm.student.courseAttendance
                                            .percentPresent < 90,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openAttendance()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.courseAttendance
                                              .percentPresent
                                          ) +
                                          "% "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "kt-section__desc" }, [
                                _vm._v(" Course Present "),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.student.schoolAttendance
                        ? _c("div", { staticClass: "col-6 p-2" }, [
                            _c("div", { staticClass: "kt-section" }, [
                              _c(
                                "div",
                                { staticClass: "kt-section__content" },
                                [
                                  _c(
                                    "span",
                                    {
                                      staticClass:
                                        "btn btn-sm kt-font-bolder scaleColorBadge",
                                      class: {
                                        "btn-success":
                                          _vm.student.schoolAttendance
                                            .percentPresent >= 90,
                                        "btn-warning":
                                          _vm.student.schoolAttendance
                                            .percentPresent < 90,
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.openAttendance()
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.schoolAttendance
                                              .percentPresent
                                          ) +
                                          "% "
                                      ),
                                    ]
                                  ),
                                ]
                              ),
                              _c("div", { staticClass: "kt-section__desc" }, [
                                _vm._v(" Daily Att Present "),
                              ]),
                            ]),
                          ])
                        : _vm._e(),
                      _vm.student.average
                        ? _vm._l(
                            _vm.student.average.markingPeriod.categories,
                            function (category) {
                              return _c(
                                "div",
                                {
                                  key: `${category.gradeTemplateCategoryId}`,
                                  staticClass: "col-6 p-2",
                                },
                                [
                                  category.categoryName != "Uncategorized" &&
                                  category.scaled
                                    ? _c("div", { staticClass: "kt-section" }, [
                                        _c(
                                          "div",
                                          {
                                            staticClass: "kt-section__content",
                                            class: category.scaled
                                              ? category.scaled.color
                                              : "",
                                          },
                                          [
                                            _vm.scaledDisplayPreference ===
                                              "All" ||
                                            _vm.scaledDisplayPreference ===
                                              "ShowScale"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "btn btn-sm btn-icon btn-elevate btn-circle kt-font-bolder scaleColorBadge",
                                                    class: category.scaled
                                                      ? category.scaled.color
                                                      : "lightgrey",
                                                    on: {
                                                      click: function ($event) {
                                                        return _vm.openBreakdown(
                                                          category.categoryName
                                                        )
                                                      },
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          category.scaled
                                                            ? category.scaled
                                                                .mark
                                                            : "N/A"
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                            _vm.scaledDisplayPreference ===
                                              "All" ||
                                            _vm.scaledDisplayPreference ===
                                              "ShowPercentage"
                                              ? _c(
                                                  "span",
                                                  {
                                                    staticClass:
                                                      "font kt-font-bolder ml-1",
                                                    class: category.scaled
                                                      ? category.scaled.color
                                                      : "lightgrey",
                                                  },
                                                  [
                                                    _vm._v(
                                                      " " +
                                                        _vm._s(
                                                          _vm.percentage(
                                                            category.percentEarned
                                                          )
                                                        ) +
                                                        " "
                                                    ),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "kt-section__desc" },
                                          [
                                            _vm._v(
                                              " " +
                                                _vm._s(category.categoryName) +
                                                " "
                                            ),
                                          ]
                                        ),
                                      ])
                                    : _vm._e(),
                                ]
                              )
                            }
                          )
                        : _vm._e(),
                    ],
                    2
                  ),
                ]
              ),
            ]
          )
        : _vm._e(),
      _c("b-modal", {
        ref: "student-breakdown-modal",
        attrs: { size: "xl", variant: "primary", static: "", centered: "" },
        scopedSlots: _vm._u([
          {
            key: "modal-header",
            fn: function ({ close }) {
              return [
                _vm.selected.gradingCategory
                  ? _c("h5", { staticClass: "modal-title" }, [
                      _vm._v(
                        " " +
                          _vm._s(_vm.student.lastName) +
                          ", " +
                          _vm._s(_vm.student.firstName) +
                          " "
                      ),
                    ])
                  : _vm._e(),
                _c("button", {
                  staticClass: "close",
                  attrs: {
                    type: "button",
                    "data-dismiss": "modal",
                    "aria-label": "Close",
                  },
                  on: {
                    click: function ($event) {
                      return close()
                    },
                  },
                }),
              ]
            },
          },
          {
            key: "default",
            fn: function () {
              return [_c("div", { staticClass: "modal-body" })]
            },
            proxy: true,
          },
          {
            key: "modal-footer",
            fn: function () {
              return undefined
            },
            proxy: true,
          },
        ]),
      }),
      _vm.student
        ? _c("b-modal", {
            ref: "student-attendance-modal",
            attrs: { size: "lg", variant: "primary", static: "", centered: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "modal-header",
                  fn: function ({ close }) {
                    return [
                      _c("h5", { staticClass: "modal-title" }, [
                        _vm._v(
                          " " +
                            _vm._s(_vm.student.lastName) +
                            ", " +
                            _vm._s(_vm.student.firstName) +
                            " "
                        ),
                      ]),
                      _c("button", {
                        staticClass: "close",
                        attrs: {
                          type: "button",
                          "data-dismiss": "modal",
                          "aria-label": "Close",
                        },
                        on: {
                          click: function ($event) {
                            return close()
                          },
                        },
                      }),
                    ]
                  },
                },
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("div", { staticClass: "modal-body" }, [
                        _vm.student.courseAttendance
                          ? _c("div", { staticClass: "kt-section" }, [
                              _c("div", { staticClass: "kt-section__title" }, [
                                _vm._v(" Course Attendance Totals "),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.student.courseAttendance
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-4" }, [
                                _c("div", { staticClass: "kt-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__desc" },
                                    [_vm._v(" Absent ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section__content kt-font-bolder",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.courseAttendance.absent
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-4" }, [
                                _c("div", { staticClass: "kt-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__desc" },
                                    [_vm._v(" Present ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section__content kt-font-bolder",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.courseAttendance.present
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-4" }, [
                                _c("div", { staticClass: "kt-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__desc" },
                                    [_vm._v(" Late ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section__content kt-font-bolder",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.courseAttendance.late
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.student.schoolAttendance
                          ? _c("div", { staticClass: "kt-section pt-5" }, [
                              _c("div", { staticClass: "kt-section__title" }, [
                                _vm._v(" Daily Attendance Totals "),
                              ]),
                            ])
                          : _vm._e(),
                        _vm.student.schoolAttendance
                          ? _c("div", { staticClass: "row" }, [
                              _c("div", { staticClass: "col-3" }, [
                                _c("div", { staticClass: "kt-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__desc" },
                                    [_vm._v(" Absent ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section__content kt-font-bolder",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.schoolAttendance.absent
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-3" }, [
                                _c("div", { staticClass: "kt-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__desc" },
                                    [_vm._v(" Present ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section__content kt-font-bolder",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.schoolAttendance.present
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-3" }, [
                                _c("div", { staticClass: "kt-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__desc" },
                                    [_vm._v(" Online Absent ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section__content kt-font-bolder",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.schoolAttendance
                                              .onlineAbsent
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                              _c("div", { staticClass: "col-3" }, [
                                _c("div", { staticClass: "kt-section" }, [
                                  _c(
                                    "div",
                                    { staticClass: "kt-section__desc" },
                                    [_vm._v(" Online Present ")]
                                  ),
                                  _c(
                                    "div",
                                    {
                                      staticClass:
                                        "kt-section__content kt-font-bolder",
                                    },
                                    [
                                      _vm._v(
                                        " " +
                                          _vm._s(
                                            _vm.student.schoolAttendance
                                              .onlinePresent
                                          ) +
                                          " "
                                      ),
                                    ]
                                  ),
                                ]),
                              ]),
                            ])
                          : _vm._e(),
                      ]),
                    ]
                  },
                  proxy: true,
                },
                {
                  key: "modal-footer",
                  fn: function () {
                    return undefined
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              1607600548
            ),
          })
        : _vm._e(),
      _vm.selected.standardIndex != null
        ? _c("ValidMarksModal", {
            ref: "validMarksInfo",
            attrs: {
              "apply-grade": _vm.modalGradeChange,
              standard: _vm.selected.standard,
              index: _vm.selected.standardIndex,
              "course-marks": _vm.courseMarks,
              "exam-marks": _vm.examMarks,
            },
          })
        : _vm._e(),
      _vm.selected.commentIndex != null
        ? _c("CommentCodesModal", {
            ref: "commentCodeInfo",
            attrs: {
              "apply-comment": _vm.modalCommentChange,
              index: _vm.selected.commentIndex,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }