<template>
<div>
    <StudentGradeEntry
        v-if="isVisible"
        :passed-student="passedStudent"
        :idx="idx"
        :save-student="saveStudent"
        :marking-period="markingPeriod"
        :grade-template="gradeTemplate"
        :read-only="readOnly"
    />
    <StudentGradeSkeleton
        v-if="!isVisible"
        v-waypoint="{ active: true, callback: onWaypoint, options: intersectionOptions }"
    />
</div>
</template>

<script>

import Vue from 'vue';
import VueWaypoint from 'vue-waypoint';
import StudentGradeSkeleton from './StudentGradeSkeleton.vue';
import StudentGradeEntry from './StudentGradeEntry.vue';

Vue.use(VueWaypoint);

export default {
    name: 'StudentGradeHolder',
    components: {
        StudentGradeEntry,
        StudentGradeSkeleton,
    },
    props: {
        passedStudent: {
            type: Object,
            required: true,
        },
        idx: {
            type: Number,
            required: true,
        },
        saveStudent: {
            type: Function,
            required: true,
        },
        markingPeriod: {
            type: Object,
            required: true,
        },
        gradeTemplate: {
            type: Object,
            required: true,
        },
        readOnly: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            enabled: false,
            isVisible: false,
            intersectionOptions: {
                root: null,
                rootMargin: '0px 0px 0px 0px',
                threshold: [0.25, 0.75], // [0.25, 0.75] if you want a 25% offset!
            },
        };
    },
    mounted() {
        const v = this;
        setTimeout(() => {
            v.enabled = true;
        }, 1000);
    },
    methods: {
        onWaypoint() {
            const v = this;
            if (v.enabled || v.idx <= 3) {
                v.isVisible = true;
                // console.log(`${v.idx} toggle!`);
            }
        },
    },
};
</script>
