<template>
<b-modal
    ref="valid-marks-modal"
    size="lg"
    variant="primary"
    static
    centered
    hide-footer
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Valid Marks
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>
    <template #default>
        <div
            class="modal-body"
            style="padding: 0px 10px; overflow-x: hidden"
        >
            <div
                class="row pb-3"
                style="position: sticky;top: 0; background: white; z-index: 1"
            >
                <div class="col-6">
                    <div class="kt-input-icon kt-input-icon--left">
                        <input
                            v-model="filter"
                            type="text"
                            class="form-control"
                            placeholder="Filter..."
                        >
                        <span class="kt-input-icon__icon kt-input-icon__icon--left">
                            <span>
                                <i class="la la-search" />
                            </span>
                        </span>
                    </div>
                    <span class="text-muted">Select a grade below to apply the mark to the student</span>
                </div>
                <div class="col-6">
                    <div class="form-group pull-right">
                        <div class="kt-checkbox-inline">
                            <label class="kt-checkbox">
                                <input
                                    v-model="passing"
                                    type="checkbox"
                                > Pass
                                <span />
                            </label>
                            <label class="kt-checkbox">
                                <input
                                    v-model="failing"
                                    type="checkbox"
                                > Fail
                                <span />
                            </label>
                            <label class="kt-checkbox">
                                <input
                                    v-model="special"
                                    type="checkbox"
                                > Special
                                <span />
                            </label>
                        </div>
                        <span class="form-text text-muted pull-right">Filter by type</span>
                    </div>
                </div>
            </div>

            <div
                class="kt-widget28 pt-3"
                style="width: 100%;"
            >
                <div class="kt-widget28__wrapper kt-portlet__space-x">
                    <div
                        class="tab-pane active row"
                        style="display: flex;"
                    >
                        <div
                            v-for="g in validMarks"
                            :key="'grd'+g.markingPeriodValidMarkId"
                            class="col-lg-3"
                        >
                            <div class="kt-widget28__tab-items">
                                <div
                                    class="kt-widget28__tab-item"
                                    style="cursor: pointer"
                                    @click="chooseMark(g)"
                                >
                                    <span>
                                        <span>
                                            <div style="margin: 10px 0">
                                                <span
                                                    class="colorSwatch"
                                                    :class="{'Green': g.passing, 'Red': g.passing === false, 'Gray': g.passing === null}"
                                                />
                                                {{ g.mark }}
                                                <code
                                                    v-if="g.numericEquivalent"
                                                    style="margin: 0 10px; color: #0571f7"
                                                >{{ g.numericEquivalent }} %</code>
                                                <br>
                                                <small class="text-muted">{{ g.markDescription }}</small>
                                            </div>
                                        </span>
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'ValidMarksModal',
    props: {
        applyGrade: {
            type: Function,
            required: true,
        },
        standard: {
            type: Object,
            required: false,
            default: null,
        },
        courseMarks: {
            type: Boolean,
            required: false,
            default: true,
        },
        examMarks: {
            type: Boolean,
            required: false,
            default: false,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            filter: '',
            passing: true,
            failing: true,
            special: true,
        };
    },
    computed: mapState({
        validMarks(state) {
            const v = this;
            const relevantValidMarks = (v.courseMarks)
                ? state.database.reportCards.courseValidMarks
                : (v.examMarks)
                    ? state.database.reportCards.examValidMarks
                    : state.database.reportCards.validMarks;
            if (!v.filter) {
                relevantValidMarks.filter((m) => (v.passing && m.passing == true)
                    || (v.failing && m.passing == false)
                    || (v.special && m.passing == null));
            }
            const filter = v.filter.toLowerCase();
            return relevantValidMarks.filter((m) => m.mark.toLowerCase().indexOf(filter) > -1);
        },
    }),
    methods: {
        open() {
            const v = this;
            v.load();
            v.$refs['valid-marks-modal'].show();
        },
        load() {
            const v = this;
            v.filter = '';
        },
        chooseMark(mark) {
            const v = this;
            v.applyGrade(mark, v.standard, v.index);
            v.$refs['valid-marks-modal'].hide();
        },
    },
};
</script>

<style scoped>
.kt-section__desc {
    margin-bottom: 0.4rem;
}
.modal-dialog {
    overflow-y: initial !important;
}
.modal-body {
    max-height: 75vh;
    overflow-y: auto;
}
</style>
