<template>
<div>
    <div
        v-if="student"
        :key="`${student.courseSectionStudentId}_${key}`"
        class="d-flex p-2"
    >
        <div class="col-12 align-self-stretch flex-fill stars-box">
            <div class="d-flex">
                <div class="p-2 mr-auto">
                    <div
                        class="kt-widget5 p-0"
                        style="max-height: 170px; min-width: 220px;"
                    >
                        <div class="kt-widget5__item pb-0 mb-0">
                            <div class="kt-widget5__content">
                                <div
                                    class="kt-widget5__pic pr-0"
                                    style="width: 50px; max-height: 49px; overflow: hidden; margin: 0;"
                                >
                                    <StudentAvatar
                                        v-if="encryptionEnabled"
                                        style="width: 50px; height: 50px; padding-right: 10px"
                                        :student="student"
                                    />
                                    <UserAvatar
                                        v-else
                                        :avatar-user="student"
                                        style="max-height: 38px; max-width: 38px; margin: 6px;"
                                        class="kt-widget7__img"
                                    >
                                        <div class="kt-media kt-media--sm">
                                            <a
                                                style="max-width: 38px;margin-left: 6px;margin-right: 6px;"
                                                class="kt-media kt-media--lg kt-media--success"
                                            >
                                                <span style="height: 1.75rem; font-size: 0.75rem">
                                                    {{ initials }}
                                                </span>
                                            </a>
                                        </div>
                                    </UserAvatar>
                                </div>
                                <div
                                    class="kt-widget5__section"
                                    style="white-space: nowrap; width: 165px; "
                                >
                                    <a
                                        href="#"
                                        style="white-space: nowrap;"
                                        class="kt-widget5__title pb-0 w-100"
                                        @click.stop.prevent="openPanelForStudent(student)"
                                    >
                                        <template v-if="!clientSideEncryptionEnabled">
                                            {{ student.lastName }}, {{ student.firstName }}
                                        </template>
                                        <template v-else>
                                            {{ student.maskedStudentName }}
                                        </template>
                                    </a>
                                    <p
                                        v-if="student.courseSection"
                                        class="kt-widget5__desc p-0"
                                    >
                                        <span v-if="student.courseSection.courseSectionIcon">
                                            {{ student.courseSection.courseSectionIcon }}
                                        </span>
                                        {{ student.courseSection.courseSectionTitle }}
                                    </p>
                                </div>
                            </div>
                            <div class="kt-widget5__content">
                                <div class="kt-widget5__stats">
                                    <span class="kt-widget5__number" />
                                    <span class="kt-widget5__sales">{{ student.homeRoom }}</span>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="shouldShowFinal"
                    class="pt-3"
                >
                    <span class="kt-switch kt-switch--outline kt-switch--icon kt-switch--success kt-switch--sm">
                        <label>
                            <label class="p-2 kt-font-bold font">Final Grade</label>
                            <input
                                v-model="student.grade.isFinal"
                                type="checkbox"
                                name="quick_panel_notifications_1"
                                :disabled="readOnly"
                                @change="keyUp($event, 'isFinal')"
                            >
                            <span />
                        </label>
                    </span>
                </div>
            </div>
            <div
                class="d-flex p-0 col-12"
                style="justify-content: space-evenly"
            >
                <div
                    class="d-flex p-0 mt-3"
                    :class="shouldShowExamGrade ? 'col-6' : 'col-12'"
                    style="align-items: center;"
                >
                    <div class="p-2 col-8">
                        <div
                            class="form-group"
                            style="margin-bottom: 0"
                        >
                            <div class="input-group input-group-sm">
                                <vue-typeahead-bootstrap
                                    ref="overall_grade"
                                    v-b-tooltip.v-primary.hover
                                    :title="'Enter the student\'s overall grade here'"
                                    :data="courseValidMarks"
                                    :show-on-focus="true"
                                    :value="student.grade.mark ? `${student.grade.mark}` : ''"
                                    :serializer="m => `${m.mark}`"
                                    :disabled="readOnly"
                                    placeholder="Overall Grade"
                                    @keyup="keyUp($event, 'overall_grade')"
                                    @hit="keyUp($event, 'overall_grade')"
                                />
                                <div
                                    v-b-tooltip.v-primary.hover
                                    :title="'Click here to see all available marks'"
                                    class="input-group-append"
                                >
                                    <span
                                        class="input-group-text"
                                        :style="`cursor: ${!readOnly ? 'pointer' : ''}`"
                                        @click="validGradeModal('course')"
                                    >
                                        <div
                                            v-if="student.grade.savingOverall || student.grade.dirtyOverall"
                                            style="margin-right: 18px;"
                                            class="kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info"
                                        />
                                        <i
                                            v-else-if="student.grade.saveStatus"
                                            class="fa fa-times text-danger"
                                            aria-hidden="true"
                                        />
                                        <i
                                            v-else
                                            class="fa fa-spell-check font"
                                            :class="{'Red': student.grade.passing == false, 'Green': student.grade.passing == true}"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-2 col-4 sg-abs-width">
                        <span
                            class="kt-font-bold font"
                            :class="{'Red': student.grade.passing == false, 'Green': student.grade.passing == true}"
                        >
                            Overall Grade
                        </span>
                    </div>
                </div>
                <div
                    v-if="shouldShowExamGrade"
                    class="d-flex col-6 mt-3"
                    style="align-items: center;"
                >
                    <div class="p-2 col-8">
                        <div
                            class="form-group"
                            style="margin-bottom: 0"
                        >
                            <div class="input-group input-group-sm">
                                <vue-typeahead-bootstrap
                                    ref="exam_grade"
                                    v-b-tooltip.v-primary.hover
                                    :title="'Enter the student\'s exam grade here'"
                                    :data="examValidMarks"
                                    :show-on-focus="true"
                                    :value="student.grade.examMark ? `${student.grade.examMark}` : ''"
                                    :serializer="m => `${m.mark}`"
                                    :disabled="readOnly"
                                    placeholder="Exam Grade"
                                    @keyup="keyUp($event, 'exam_grade')"
                                    @hit="keyUp($event, 'exam_grade')"
                                />
                                <div
                                    v-b-tooltip.v-primary.hover
                                    :title="'Click here to see all available marks'"
                                    class="input-group-append"
                                >
                                    <span
                                        class="input-group-text"
                                        style="cursor: pointer"
                                        @click="validGradeModal('exam')"
                                    >
                                        <div
                                            v-if="student.grade.savingExam || student.grade.dirtyExam"
                                            style="margin-right: 18px;"
                                            class="kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info"
                                        />
                                        <i
                                            v-else-if="student.grade.saveStatus"
                                            class="fa fa-times text-danger"
                                            aria-hidden="true"
                                        />
                                        <i
                                            v-else
                                            class="fa fa-spell-check font"
                                            :class="{'Red': student.grade.passing == false, 'Green': student.grade.passing == true}"
                                        />
                                    </span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="p-2 col-4 sg-abs-width">
                        <span
                            class="kt-font-bold font"
                            :class="{'Red': student.grade.passing == false, 'Green': student.grade.passing == true}"
                        >
                            Exam Grade
                        </span>
                    </div>
                </div>
            </div>
            <div
                v-if="shouldShowConduct"
                class="d-flex mt-3"
                style="align-items: center; width: 100%;"
            >
                <div
                    class="p-2"
                    :class="shouldShowExamGrade ? 'col-10' : 'col-8'"
                >
                    <div
                        class="form-group"
                        style="margin-bottom: 0"
                    >
                        <div class="input-group input-group-sm">
                            <select
                                v-model="student.grade.conduct"
                                class="form-control"
                                :disabled="readOnly"
                                @input="keyUp($event, 'conduct')"
                            >
                                <option
                                    v-for="conduct in conducts"
                                    :key="conduct.shortName"
                                    :value="conduct.shortName"
                                    :selected="student.grade.conduct == conduct.shortName"
                                >
                                    {{ conduct.shortName }} - {{ conduct.longName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div
                    class="p-2 sg-abs-width"
                    :class="shouldShowExamGrade ? 'col-2' : 'col-4'"
                >
                    <span
                        class="kt-font-bold font"
                        :class="{'Green': student.grade.conduct}"
                    >
                        Conduct
                    </span>
                </div>
            </div>
            <div
                v-if="shouldShowLevel"
                class="d-flex mt-3"
                style="align-items: center; width: 100%;"
            >
                <div
                    class="p-2"
                    :class="shouldShowExamGrade ? 'col-10' : 'col-8'"
                >
                    <div
                        class="form-group"
                        style="margin-bottom: 0"
                    >
                        <div class="input-group input-group-sm">
                            <select
                                v-model="student.grade.level"
                                class="form-control"
                                :disabled="readOnly"
                                @input="keyUp($event, 'level')"
                            >
                                <option
                                    v-for="level in levels"
                                    :key="level.number"
                                    :value="level.number"
                                    :selected="student.grade.level == level.number"
                                >
                                    {{ level.number }} - {{ level.longName }}
                                </option>
                            </select>
                        </div>
                    </div>
                </div>
                <div
                    class="p-2 sg-abs-width"
                    :class="shouldShowExamGrade ? 'col-2' : 'col-4'"
                >
                    <span
                        class="kt-font-bold font"
                        :class="{'Green': student.grade.level}"
                    >
                        Level
                    </span>
                </div>
            </div>
            <!-- <h6
                v-if="shouldShowCourseStandards"
                class="mt-4"
            >
                Standards Grades
            </h6>
            <div
                v-for="(standard, idx) in student.courseStandards"
                :key="`${standard.schoolStandardId}_${student.courseSectionStudentId}`"
                class="d-flex"
                style="align-items: center;"
            >
                <div class="p-2 col-4">
                    <div
                        class="form-group"
                        style="margin-bottom: 0px"
                    >
                        <div class="input-group input-group-sm">
                            <vue-typeahead-bootstrap
                                :ref="`standardGrade_${idx}`"
                                v-b-tooltip.v-primary.hover
                                :title="'Enter the students\' standard based grade here'"
                                :data="courseValidMarks"
                                :size="'sm'"
                                :show-on-focus="true"
                                :value="standard.grade.mark ? `${standard.grade.mark}` : ''"
                                :serializer="m => `${m.mark}`"
                                placeholder="Standard Grade"
                                @keyup="keyUp($event, `standardGrade_${idx}`, idx)"
                                @hit="keyUp($event, `standardGrade_${idx}`, idx)"
                            />
                            <div
                                v-b-tooltip.v-primary.hover
                                :title="'Click here to see all available marks'"
                                class="input-group-append"
                            >
                                <span
                                    class="input-group-text"
                                    style="cursor: pointer"
                                    @click="standardsValidGradeModal(standard, idx)"
                                >
                                    <div
                                        v-if="standard.grade.saving || standard.grade.dirty"
                                        style="margin-right: 18px;"
                                        class="kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info"
                                    />
                                    <i
                                        v-else-if="standard.grade.saveStatus"
                                        class="fa fa-times text-danger"
                                        aria-hidden="true"
                                    />
                                    <i
                                        v-else
                                        class="fa fa-spell-check font"
                                        :class="{'Red': standard.grade.passing == false, 'Green': standard.grade.passing == true}"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="p-2 col-8">
                    <span
                        class="font"
                        :class="{'Red': standard.grade.passing == false, 'Green': standard.grade.passing == true}"
                    >{{ standard.standardDescription }}</span>
                </div>
            </div> -->
            <h6
                v-if="shouldShowComments"
                class="mt-4"
            >
                Comments
            </h6>
            <div
                v-if="shouldShowComments"
                class="d-flex"
            >
                <div
                    class="p-2 mr-4 flex-fill"
                    style="margin-bottom: -25px;"
                >
                    <div
                        v-if="shouldShowCommentOne"
                        class="form-group"
                    >
                        <div class="input-group input-group-sm">
                            <vue-typeahead-bootstrap
                                ref="comment1"
                                :data="commentCodes"
                                :size="'sm'"
                                :show-on-focus="true"
                                :value="student.grade.comments[0].commentCode ? `${student.grade.comments[0].commentCode}: ${student.grade.comments[0].commentDescription}` : ''"
                                :serializer="comment => `${comment.commentCode}: ${comment.commentDescription}`"
                                :disabled="readOnly"
                                placeholder="Comment Code 1"
                                @keyup="keyUp($event, 'comment1', 0)"
                                @hit="keyUp($event, 'comment1', 0)"
                            />
                            <div class="input-group-append">
                                <span
                                    v-b-tooltip.v-primary.hover
                                    title="Click here to see all comment codes"
                                    class="input-group-text"
                                >
                                    <div
                                        v-if="student.grade.comments[0].saving || student.grade.comments[0].dirty"
                                        style="margin-right: 18px;"
                                        class="kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info"
                                    />
                                    <i
                                        v-else-if="student.grade.comments[0].saveStatus"
                                        class="fa fa-times text-danger"
                                        aria-hidden="true"
                                    />
                                    <i
                                        v-else
                                        class="fa fa-comment font"
                                        :class="{'Blue': student.grade.comments[0].commentCode}"
                                        :style="`cursor: ${!readOnly ? 'pointer' : ''}`"
                                        @click="commentCodeModal(0)"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="shouldShowCommentTwo"
                        class="form-group"
                    >
                        <div class="input-group input-group-sm">
                            <vue-typeahead-bootstrap
                                ref="comment2"
                                :data="commentCodes"
                                :size="'sm'"
                                :show-on-focus="true"
                                :value="student.grade.comments[1].commentCode ? `${student.grade.comments[1].commentCode}: ${student.grade.comments[1].commentDescription}` : ''"
                                :serializer="comment => `${comment.commentCode}: ${comment.commentDescription}`"
                                :disabled="readOnly"
                                placeholder="Comment Code 2"
                                @keyup="keyUp($event, 'comment2', 1)"
                                @hit="keyUp($event, 'comment2', 1)"
                            />
                            <div class="input-group-append">
                                <span
                                    v-b-tooltip.v-primary.hover
                                    title="Click here to see all comment codes"
                                    class="input-group-text"
                                >
                                    <div
                                        v-if="student.grade.comments[1].saving || student.grade.comments[1].dirty"

                                        style="margin-right: 18px;"
                                        class="kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info"
                                    />
                                    <i
                                        v-else-if="student.grade.comments[1].saveStatus"
                                        class="fa fa-times text-danger"
                                        aria-hidden="true"
                                    />
                                    <i
                                        v-else
                                        class="fa fa-comment font"
                                        :class="{'Blue': student.grade.comments[1].commentCode}"
                                        :style="`cursor: ${!readOnly ? 'pointer' : ''}`"
                                        @click="commentCodeModal(1)"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-if="shouldShowCommentThree"
                        class="form-group"
                    >
                        <div class="input-group input-group-sm">
                            <vue-typeahead-bootstrap
                                ref="comment3"
                                :data="commentCodes"
                                :size="'sm'"
                                :show-on-focus="true"
                                :value="student.grade.comments[2].commentCode ? `${student.grade.comments[2].commentCode}: ${student.grade.comments[2].commentDescription}` : ''"
                                :serializer="comment => `${comment.commentCode}: ${comment.commentDescription}`"
                                :disabled="readOnly"
                                placeholder="Comment Code 3"
                                @keyup="keyUp($event, 'comment3', 2)"
                                @hit="keyUp($event, 'comment3', 2)"
                            />
                            <div class="input-group-append">
                                <span
                                    v-b-tooltip.v-primary.hover
                                    title="Click here to see all comment codes"
                                    class="input-group-text"
                                >
                                    <div
                                        v-if="student.grade.comments[2].saving || student.grade.comments[2].dirty"

                                        style="margin-right: 18px;"
                                        class="kt-spinner kt-spinner--v2 kt-spinner--sm kt-spinner--info"
                                    />
                                    <i
                                        v-else-if="student.grade.comments[2].saveStatus"
                                        class="fa fa-times text-danger"
                                        aria-hidden="true"
                                    />
                                    <i
                                        v-else
                                        class="fa fa-comment font"
                                        :class="{'Blue': student.grade.comments[2].commentCode}"
                                        :style="`cursor: ${!readOnly ? 'pointer' : ''}`"
                                        @click="commentCodeModal(2)"
                                    />
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <h6
                v-if="markingPeriod.narrative"
                class="mt-4"
            >
                Narrative
            </h6>
            <div
                v-if="markingPeriod.narrative"
                class="d-flex"
            >
                <div class="p-2 mr-4 flex-fill">
                    <div class="form-group validated">
                        <div
                            v-if="student.grade.narrativeDirty"
                            class="kt-spinner kt-spinner--v2 kt-spinner--md kt-spinner--info comment"
                        />
                        <textarea
                            v-model="student.grade.narrativeComment"
                            class="form-control"
                            :class="{'is-invalid': student.grade.narrativeSaveStatus}"
                            :disabled="readOnly"
                            placeholder="Narrative comment"
                            rows="2"
                            @keyup="keyUp($event, 'narrative')"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 align-self-stretch flex-fill google-box">
            <div class="row">
                <div v-if="markingPeriod.cumulative && !Number.isNaN(student.cumulativeReportCardAverage)" class="col-12 p-2">
                    <div class="kt-section">
                        <div class="kt-section__content">
                            <span
                                class="btn btn-sm kt-font-bolder scaleColorBadge"
                                :class="{
                                    'btn-success': student.cumulativeReportCardAverage >= 0.9,
                                    'btn-primary': student.cumulativeReportCardAverage >= 0.7 && student.cumulativeReportCardAverage < 0.9,
                                    'btn-warning': student.cumulativeReportCardAverage >= 0.5 && student.cumulativeReportCardAverage < 0.7,
                                    'btn-danger': student.cumulativeReportCardAverage < 0.5
                                }"
                            >
                                {{ percentage(student.cumulativeReportCardAverage) }}
                            </span>
                        </div>
                        <div class="kt-section__desc">
                            Estimated Cumulative Report Card Average
                        </div>
                    </div>
                </div>
                <div
                    v-if="student.average && (scaledDisplayPreference === 'All' || scaledDisplayPreference === 'ShowPercentage')"
                    class="col-6 p-2"
                >
                    <div class="kt-section">
                        <div class="kt-section__content">
                            <span
                                :class="student.average.markingPeriod.scaled ? student.average.markingPeriod.scaled.color : 'lightgrey'"
                                class="btn btn-sm kt-font-bolder scaleColorBadge"
                                @click="openBreakdown('Overall')"
                            >
                                {{ percentage(student.average.markingPeriod.average) }}
                            </span>
                        </div>
                        <div class="kt-section__desc">
                            Average
                        </div>
                    </div>
                </div>

                <div
                    v-if="student.average && (scaledDisplayPreference === 'All' || scaledDisplayPreference === 'ShowScale')"
                    class="col-6 p-2"
                >
                    <div class="kt-section">
                        <div class="kt-section__content">
                            <span
                                :class="student.average.markingPeriod.scaled ? student.average.markingPeriod.scaled.color : 'lightgrey'"
                                class="btn btn-sm btn-icon btn-elevate btn-circle kt-font-bolder scaleColorBadge"
                                @click="openBreakdown('Overall')"
                            >
                                {{ student.average.markingPeriod.scaled ? student.average.markingPeriod.scaled.mark : 'N/A' }}
                            </span>
                        </div>
                        <div class="kt-section__desc">
                            Scaled
                        </div>
                    </div>
                </div>
                <div
                    v-if="scaledDisplayPreference !== 'All'"
                    class="col-6 p-2"
                />

                <div
                    v-if="student.courseAttendance"
                    class="col-6 p-2"
                >
                    <div class="kt-section">
                        <div class="kt-section__content">
                            <span
                                class="btn btn-sm kt-font-bolder scaleColorBadge"
                                :class="{'btn-success': student.courseAttendance.percentPresent >= 90, 'btn-warning': student.courseAttendance.percentPresent < 90 }"
                                @click="openAttendance()"
                            >
                                {{ student.courseAttendance.percentPresent }}%
                            </span>
                        </div>
                        <div class="kt-section__desc">
                            Course Present
                        </div>
                    </div>
                </div>
                <div
                    v-if="student.schoolAttendance"
                    class="col-6 p-2"
                >
                    <div class="kt-section">
                        <div class="kt-section__content">
                            <span
                                class="btn btn-sm kt-font-bolder scaleColorBadge"
                                :class="{'btn-success': student.schoolAttendance.percentPresent >= 90, 'btn-warning': student.schoolAttendance.percentPresent < 90 }"
                                @click="openAttendance()"
                            >
                                {{ student.schoolAttendance.percentPresent }}%
                            </span>
                        </div>
                        <div class="kt-section__desc">
                            Daily Att Present
                        </div>
                    </div>
                </div>
                <template v-if="student.average">
                    <div
                        v-for="category in student.average.markingPeriod.categories"
                        :key="`${category.gradeTemplateCategoryId}`"
                        class="col-6 p-2"
                    >
                        <div
                            v-if="category.categoryName != 'Uncategorized' && category.scaled"
                            class="kt-section"
                        >
                            <div
                                class="kt-section__content"
                                :class="category.scaled ? category.scaled.color: ''"
                            >
                                <span
                                    v-if="scaledDisplayPreference === 'All' || scaledDisplayPreference === 'ShowScale'"
                                    :class="category.scaled ? category.scaled.color: 'lightgrey'"
                                    class="btn btn-sm btn-icon btn-elevate btn-circle kt-font-bolder scaleColorBadge"
                                    @click="openBreakdown(category.categoryName)"
                                >
                                    {{ category.scaled ? category.scaled.mark : 'N/A' }}
                                </span>
                                <span
                                    v-if="scaledDisplayPreference === 'All' || scaledDisplayPreference === 'ShowPercentage'"
                                    :class="category.scaled ? category.scaled.color: 'lightgrey'"
                                    class="font kt-font-bolder ml-1"
                                >
                                    {{ percentage(category.percentEarned) }}
                                </span>
                            </div>
                            <div class="kt-section__desc">
                                {{ category.categoryName }}
                            </div>
                        </div>
                    </div>
                </template>
            </div>
        </div>
    </div>

    <b-modal
        ref="student-breakdown-modal"
        size="xl"
        variant="primary"
        static
        centered
    >
        <template #modal-header="{ close }">
            <h5
                v-if="selected.gradingCategory"
                class="modal-title"
            >
                {{ student.lastName }}, {{ student.firstName }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
            />
        </template>
        <template #default>
            <div class="modal-body">
                <!-- <StudentBreakdown
                    v-if="selected.gradingCategory"
                    :student-enrollment-id="student.studentEnrollmentId"
                    :course-section-id="student.courseSectionId"
                    :school-term-marking-period-id="student.average.markingPeriod.schoolTermMarkingPeriodId"
                    :grading-category="selected.gradingCategory"
                /> -->
            </div>
        </template>
        <template #modal-footer />
    </b-modal>

    <b-modal
        v-if="student"
        ref="student-attendance-modal"
        size="lg"
        variant="primary"
        static
        centered
    >
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                {{ student.lastName }}, {{ student.firstName }}
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
            />
        </template>
        <template #default>
            <div class="modal-body">
                <div
                    v-if="student.courseAttendance"
                    class="kt-section"
                >
                    <div class="kt-section__title">
                        Course Attendance Totals
                    </div>
                </div>
                <div
                    v-if="student.courseAttendance"
                    class="row"
                >
                    <div class="col-4">
                        <div class="kt-section">
                            <div class="kt-section__desc">
                                Absent
                            </div>
                            <div class="kt-section__content kt-font-bolder">
                                {{ student.courseAttendance.absent }}
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="kt-section">
                            <div class="kt-section__desc">
                                Present
                            </div>
                            <div class="kt-section__content kt-font-bolder">
                                {{ student.courseAttendance.present }}
                            </div>
                        </div>
                    </div>
                    <div class="col-4">
                        <div class="kt-section">
                            <div class="kt-section__desc">
                                Late
                            </div>
                            <div class="kt-section__content kt-font-bolder">
                                {{ student.courseAttendance.late }}
                            </div>
                        </div>
                    </div>
                </div>
                <div
                    v-if="student.schoolAttendance"
                    class="kt-section pt-5"
                >
                    <div class="kt-section__title">
                        Daily Attendance Totals
                    </div>
                </div>
                <div
                    v-if="student.schoolAttendance"
                    class="row"
                >
                    <div class="col-3">
                        <div class="kt-section">
                            <div class="kt-section__desc">
                                Absent
                            </div>
                            <div class="kt-section__content kt-font-bolder">
                                {{ student.schoolAttendance.absent }}
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="kt-section">
                            <div class="kt-section__desc">
                                Present
                            </div>
                            <div class="kt-section__content kt-font-bolder">
                                {{ student.schoolAttendance.present }}
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="kt-section">
                            <div class="kt-section__desc">
                                Online Absent
                            </div>
                            <div class="kt-section__content kt-font-bolder">
                                {{ student.schoolAttendance.onlineAbsent }}
                            </div>
                        </div>
                    </div>
                    <div class="col-3">
                        <div class="kt-section">
                            <div class="kt-section__desc">
                                Online Present
                            </div>
                            <div class="kt-section__content kt-font-bolder">
                                {{ student.schoolAttendance.onlinePresent }}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </template>
        <template #modal-footer />
    </b-modal>

    <ValidMarksModal
        v-if="selected.standardIndex != null"
        ref="validMarksInfo"
        :apply-grade="modalGradeChange"
        :standard="selected.standard"
        :index="selected.standardIndex"
        :course-marks="courseMarks"
        :exam-marks="examMarks"
    />
    <CommentCodesModal
        v-if="selected.commentIndex != null"
        ref="commentCodeInfo"
        :apply-comment="modalCommentChange"
        :index="selected.commentIndex"
    />
</div>
</template>

<script>
import { mapState } from 'vuex';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import deepcopy from 'deepcopy';
import UserAvatar from './UserAvatar.vue';
import StudentAvatar from './StudentAvatar.vue';
import network from '../lib/network';
import ValidMarksModal from './ValidMarksModal.vue';
import CommentCodesModal from './CommentCodesModal.vue';
import panelMixins from '../store/mixins/panelMixins';
import courseMixins from '../store/mixins/courseMixins';

export default {
    name: 'StudentGradeEntry',
    components: {
        StudentAvatar,
        UserAvatar,
        VueTypeaheadBootstrap,
        ValidMarksModal,
        CommentCodesModal,
    },
    mixins: [panelMixins, courseMixins],
    props: {
        passedStudent: {
            type: Object,
            required: true,
        },
        idx: {
            type: Number,
            required: true,
        },
        saveStudent: {
            type: Function,
            required: true,
        },
        markingPeriod: {
            type: Object,
            required: true,
        },
        gradeTemplate: {
            type: Object,
            required: true,
        },
        readOnly: {
            type: Boolean,
            required: false,
        },
    },
    data() {
        return {
            key: 1,
            debounce: null,
            student: null,
            courseMarks: false,
            examMarks: false,
            conducts: [
                {
                    shortName: 'E',
                    longName: 'Excellent',
                },
                {
                    shortName: 'S',
                    longName: 'Satisfactory',
                },
                {
                    shortName: 'N',
                    longName: 'Needs Improvement',
                },
                {
                    shortName: 'U',
                    longName: 'Unsatisfactory',
                },
            ],
            levels: [
                {
                    number: '1',
                    longName: 'Well below standards',
                },
                {
                    number: '2',
                    longName: 'Below standards',
                },
                {
                    number: '3',
                    longName: 'Proficient',
                },
                {
                    number: '4',
                    longName: 'Excels in standards',
                },
            ],
            selected: {
                gradingCategory: '',
                standard: null,
                standardIndex: null,
                commentIndex: null,
            },
        };
    },
    computed: {
        ...mapState({
            user: (state) => state.user,
            dataSystems: (state) => state.database.dataSystems,
            validMarks: (state) => state.database.reportCards.validMarks,
            courseValidMarks: (state) => state.database.reportCards.courseValidMarks,
            examValidMarks: (state) => state.database.reportCards.examValidMarks,
            commentCodes: (state) => state.database.reportCards.commentCodes,
        }),
        encryptionEnabled() {
            return this.$store.state.user.school.clientSideEncryptionEnabled
                && this.$store.state.user.school.serverSideEncryptionEnabled;
        },
        clientSideEncryptionEnabled() {
            return this.user.school.clientSideEncryptionEnabled;
        },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        initials() {
            if (!this.passedStudent) return '';
            return this.passedStudent.lastName.substring(0, 2);
        },
        scaledDisplayPreference() {
            const { gradeTemplate } = this;
            if (!gradeTemplate) return 'All';
            return gradeTemplate.schoolScaledDisplayPreference;
        },
        shouldShowExamGrade() {
            const v = this;
            const examValidGradeCount = v.examValidMarks ? v.examValidMarks.length : 0;
            const markingPeriodExamGrade = v.markingPeriod.exam;
            const isStarsClassroom = v.dataSystems.includes('STARS Classroom');
            return examValidGradeCount > 0 && markingPeriodExamGrade && !isStarsClassroom;
        },
        shouldShowConduct() {
            const v = this;
            const isStarsClassroom = v.dataSystems.includes('STARS Classroom');
            return v.markingPeriod.conduct && !isStarsClassroom;
        },
        shouldShowLevel() {
            const v = this;
            const isStarsClassroom = v.dataSystems.includes('STARS Classroom');
            return v.markingPeriod.level && !isStarsClassroom;
        },
        shouldShowFinal() {
            const v = this;
            const isStarsClassroom = v.dataSystems.includes('STARS Classroom');
            return v.markingPeriod.final && !isStarsClassroom;
        },
        shouldShowNarrative() {
            const v = this;
            return v.markingPeriod.narrative;
        },
        shouldShowCommentOne() {
            const v = this;
            return v.markingPeriod.commentOne;
        },
        shouldShowCommentTwo() {
            const v = this;
            return v.markingPeriod.commentTwo;
        },
        shouldShowCommentThree() {
            const v = this;
            return v.markingPeriod.commentThree;
        },
        shouldShowComments() {
            const v = this;
            return v.shouldShowCommentOne || v.shouldShowCommentTwo || v.shouldShowCommentThree;
        },
    },
    mounted() {
        const v = this;
        v.student = deepcopy(v.passedStudent);
    },
    methods: {
        openPanelForStudent(student) {
            this.$_panelMixins_openPanelForStudent(student);
        },
        percentage(decimal) {
            return `${(decimal * 100).toFixed(2)}%`;
        },
        commentCodeModal(idx) {
            const v = this;
            if (v.readOnly) return;

            v.selected.commentIndex = idx;

            v.$nextTick(() => {
                v.$refs.commentCodeInfo.open();
            });
        },
        standardsValidGradeModal(standard, idx) {
            const v = this;
            v.selected.standard = standard;
            v.selected.standardIndex = idx;

            v.$nextTick(() => {
                v.$refs.validMarksInfo.open();
            });
        },
        validGradeModal(type) {
            const v = this;
            if (v.readOnly) return;

            v.courseMarks = true;
            v.examMarks = true;
            v.selected.standard = null;
            v.selected.standardIndex = -1;

            if (type == 'course') {
                v.courseMarks = true;
                v.examMarks = false;
            }
            if (type == 'exam') {
                v.courseMarks = false;
                v.examMarks = true;
            }

            v.$nextTick(() => {
                v.$refs.validMarksInfo.open();
            });
        },
        openAttendance() {
            const v = this;
            v.$nextTick(() => {
                v.$refs['student-attendance-modal'].show();
            });
        },
        openBreakdown(gradingCategory) {
            const v = this;
            v.selected.gradingCategory = '';
            v.$nextTick(() => {
                v.selected.gradingCategory = gradingCategory;
                // v.$refs['student-breakdown-modal'].show();
            });
        },
        keyUp(event, field, index) {
            const v = this;
            if (v.readOnly) return;
            const ignored = ['Tab', 'ShiftLeft', 'ShiftRight', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
            // console.log(event.code);
            if (ignored.indexOf(event.code) > -1) return;

            if (field.startsWith('comment')) {
                v.student.grade.comments[index].dirty = true;
            }
            if (field.startsWith('standard')) {
                v.student.courseStandards[index].dirty = true;
            }
            if (field == 'overall_grade') {
                v.student.grade.dirtyOverall = true;
            }
            if (field == 'exam_grade') {
                v.student.grade.dirtyExam = true;
            }

            clearTimeout(v.debounce);
            v.debounce = setTimeout(() => {
                if (['overall_grade'].includes(field)) {
                    const value = v.$refs[field].inputValue;
                    if (value == '') {
                        v.changeGrade(null, false);
                    } else {
                        const mark = v.validMarks.find((m) => `${m.mark}` == value);
                        if (mark) {
                            v.changeGrade(mark, false);
                        } else {
                            v.student.grade.dirtyOverall = false;
                            v.student.grade.saveStatus = 'Invalid Input';
                            v.showError(`Invalid report card mark entered: ${value}`);
                        }
                    }
                }

                if (['exam_grade'].includes(field)) {
                    const value = v.$refs[field].inputValue;
                    if (value == '') {
                        v.changeGrade(null, true);
                    } else {
                        const mark = v.examValidMarks.find((m) => `${m.mark}` == value);
                        if (mark) {
                            v.changeGrade(mark, true);
                        } else {
                            v.student.grade.dirtyExam = false;
                            v.student.grade.saveStatus = 'Invalid Input';
                        }
                    }
                }

                if (field == 'conduct') {
                    v.saveConduct();
                }
                if (field == 'level') {
                    v.saveLevel();
                }
                if (field == 'isFinal') {
                    v.saveFinal();
                }

                if (field == 'narrative') {
                    v.saveNarrative();
                }
                if (field.startsWith('comment')) {
                    const value = v.$refs[field].inputValue;
                    if (value == '') {
                        v.changeComment(null, index);
                    } else {
                        const commentCode = v.commentCodes.find((comment) => `${comment.commentCode}: ${comment.commentDescription}` == value);
                        if (commentCode) {
                            v.changeComment(commentCode, index);
                        } else {
                            v.student.grade.comments[index].dirty = false;
                            v.student.grade.comments[index].saveStatus = 'Invalid Input';
                        }
                    }
                }
                if (field.startsWith('standard')) {
                    const value = v.$refs[field][0].inputValue;
                    if (value == '') {
                        v.changeStandardGrade(v.student.courseStandards[index], null, index);
                    } else {
                        const mark = v.validMarks.find((m) => `${m.mark}` == value);
                        if (mark) {
                            v.changeStandardGrade(v.student.courseStandards[index], mark, index);
                        } else {
                            v.student.courseStandards[index].dirty = false;
                            v.student.courseStandards[index].saveStatus = 'Invalid Input';
                        }
                    }
                }
            }, 750);
        },
        modalGradeChange(grade, standard, index) {
            const v = this;
            if (!standard) { // overall grade or exam grade - JCB
                if (v.examMarks) {
                    v.changeGrade(grade, true);
                } else {
                    v.changeGrade(grade, false);
                }
            } else {
                v.changeStandardGrade(standard, grade, index);
            }
        },
        modalCommentChange(comment, index) {
            const v = this;
            v.changeComment(comment, index);
        },
        saveNarrative() {
            const v = this;
            if (v.readOnly) return;
            const { markingPeriod } = v;
            const { schoolTermMarkingPeriodId } = markingPeriod;

            v.student.grade.narrativeDirty = true;
            const records = [{
                courseSectionStudentId: v.student.courseSectionStudentId,
                markingPeriodValidMarkId: v.student.grade.markingPeriodValidMarkId || null,
                markingPeriodValidExamMarkId: v.student.grade.markingPeriodValidExamMarkId || null,
                narrativeComment: v.student.grade.narrativeComment || null,
                conduct: v.student.grade.conduct || null,
                level: v.student.grade.level || null,
                isFinal: v.student.grade.isFinal || null,
            }];
            network.reportCards.saveStudentGrade(v.user, schoolTermMarkingPeriodId, records, (err, response) => {
                if (err) {
                    v.student.grade.narrativeSaveStatus = 'Error Saving';
                } else {
                    v.student.grade.narrativeSaveStatus = null;
                }
                v.student.grade.narrativeDirty = false;
                v.save();
            });
        },
        saveConduct() {
            const v = this;
            if (v.readOnly) return;
            const { markingPeriod } = v;
            const { schoolTermMarkingPeriodId } = markingPeriod;

            v.student.grade.conductDirty = true;
            const records = [{
                courseSectionStudentId: v.student.courseSectionStudentId,
                markingPeriodValidMarkId: v.student.grade.markingPeriodValidMarkId || null,
                markingPeriodValidExamMarkId: v.student.grade.markingPeriodValidExamMarkId || null,
                narrativeComment: v.student.grade.narrativeComment || null,
                conduct: v.student.grade.conduct || null,
                level: v.student.grade.level || null,
                isFinal: v.student.grade.isFinal || null,
            }];
            network.reportCards.saveStudentGrade(v.user, schoolTermMarkingPeriodId, records, (err, response) => {
                if (err) {
                    v.student.grade.conductSaveStatus = 'Error Saving';
                } else {
                    v.student.grade.conductSaveStatus = null;
                }
                v.student.grade.conductDirty = false;
                v.save();
            });
        },
        saveLevel() {
            const v = this;
            if (v.readOnly) return;
            const { markingPeriod } = v;
            const { schoolTermMarkingPeriodId } = markingPeriod;

            v.student.grade.levelDirty = true;
            const records = [{
                courseSectionStudentId: v.student.courseSectionStudentId,
                markingPeriodValidMarkId: v.student.grade.markingPeriodValidMarkId || null,
                markingPeriodValidExamMarkId: v.student.grade.markingPeriodValidExamMarkId || null,
                narrativeComment: v.student.grade.narrativeComment || null,
                conduct: v.student.grade.conduct || null,
                level: v.student.grade.level || null,
                isFinal: v.student.grade.isFinal || null,
            }];
            network.reportCards.saveStudentGrade(v.user, schoolTermMarkingPeriodId, records, (err, response) => {
                if (err) {
                    v.student.grade.levelSaveStatus = 'Error Saving';
                } else {
                    v.student.grade.levelSaveStatus = null;
                }
                v.student.grade.levelDirty = false;
                v.save();
            });
        },
        saveFinal() {
            const v = this;
            if (v.readOnly) return;
            const { markingPeriod } = v;
            const { schoolTermMarkingPeriodId } = markingPeriod;

            v.student.grade.isFinalDirty = true;
            const records = [{
                courseSectionStudentId: v.student.courseSectionStudentId,
                markingPeriodValidMarkId: v.student.grade.markingPeriodValidMarkId || null,
                markingPeriodValidExamMarkId: v.student.grade.markingPeriodValidExamMarkId || null,
                narrativeComment: v.student.grade.narrativeComment || null,
                conduct: v.student.grade.conduct || null,
                level: v.student.grade.level || null,
                isFinal: v.student.grade.isFinal || false,
            }];
            network.reportCards.saveStudentGrade(v.user, schoolTermMarkingPeriodId, records, (err, response) => {
                if (err) {
                    v.student.grade.isFinalSaveStatus = 'Error Saving';
                } else {
                    v.student.grade.isFinalSaveStatus = null;
                }
                v.student.grade.isFinalDirty = false;
                v.save();
            });
        },
        changeGrade(grade, isExamGrade) {
            const v = this;
            const { markingPeriod, course } = v;
            const { schoolTermMarkingPeriodId } = markingPeriod;

            console.log('grade', grade);

            if (isExamGrade) {
                v.student.grade.savingExam = true;
                v.student.grade.savingOverall = false;
                v.student.grade.dirtyExam = true;
                v.student.grade.dirtyOverall = false;
            } else {
                v.student.grade.savingExam = false;
                v.student.grade.savingOverall = true;
                v.student.grade.dirtyExam = false;
                v.student.grade.dirtyOverall = true;
            }
            v.student.grade.saveStatus = null;
            v.student.grade.passing = grade ? grade.passing : null;

            if (isExamGrade) {
                v.student.grade.markingPeriodValidExamMarkId = grade ? grade.markingPeriodValidMarkId : 0;
                v.student.grade.examMark = grade ? grade.mark : '';
            } else {
                v.student.grade.markingPeriodValidMarkId = grade ? grade.markingPeriodValidMarkId : 0;
                v.student.grade.mark = grade ? grade.mark : '';
            }

            const records = [{
                courseSectionStudentId: v.student.grade.courseSectionStudentId,
                markingPeriodValidMarkId: v.student.grade.markingPeriodValidMarkId || null,
                markingPeriodValidExamMarkId: v.student.grade.markingPeriodValidExamMarkId || null,
                narrativeComment: v.student.grade.narrativeComment || null,
                conduct: v.student.grade.conduct || null,
                level: v.student.grade.level || null,
                isFinal: v.student.grade.isFinal || false,
            }];
            network.reportCards.saveStudentGrade(v.user, schoolTermMarkingPeriodId, records, (err, response) => {
                v.student.grade.savingExam = false;
                v.student.grade.savingOverall = false;
                v.student.grade.dirtyExam = false;
                v.student.grade.dirtyOverall = false;
                if (err) {
                    v.student.grade.saveStatus = 'Error Saving';
                } else {
                    v.student.grade.saveStatus = null;
                }
                v.save();
            });
        },
        changeStandardGrade(standard, grade, index) {
            const v = this;
            const { markingPeriod } = v;
            const { schoolTermMarkingPeriodId } = markingPeriod;

            v.student.courseStandards[index].grade.saving = true;
            v.student.courseStandards[index].grade.dirty = true;
            v.student.courseStandards[index].grade.saveStatus = null;
            v.student.courseStandards[index].grade.mark = grade ? grade.mark : '';
            v.student.courseStandards[index].grade.markingPeriodValidMarkId = grade ? grade.markingPeriodValidMarkId : 0;
            v.student.courseStandards[index].grade.passing = grade ? grade.passing : null;

            const records = [{
                courseSectionStudentId: v.student.courseSectionStudentId,
                markingPeriodValidMarkId: standard.grade.markingPeriodValidMarkId,
                markingPeriodValidExamMarkId: null,
                schoolStandardId: standard.schoolStandardId,
                conduct: v.student.grade.conduct || null,
                level: v.student.grade.level || null,
                isFinal: false,
            }];

            network.reportCards.saveStudentStandardGrade(v.user, schoolTermMarkingPeriodId, records, (err, response) => {
                v.student.courseStandards[index].grade.saving = false;
                v.student.courseStandards[index].grade.dirty = false;
                if (err) {
                    v.student.courseStandards[index].grade.saveStatus = 'Error Saving';
                } else {
                    v.student.courseStandards[index].grade.saveStatus = null;
                }
                v.save();
            });
        },
        save() {
            const v = this;
            if (v.readOnly) return;
            const { student } = v;
            v.saveStudent(student, v.idx);
        },
        changeComment(commentCode, index) {
            const v = this;
            if (v.readOnly) return;
            const { markingPeriod, course } = v;
            const { schoolTermMarkingPeriodId } = markingPeriod;
            const { courseSectionId } = course;

            v.student.grade.comments[index].saving = true;
            v.student.grade.comments[index].dirty = true;
            v.student.grade.comments[index].saveStatus = null;
            v.student.grade.comments[index].markingPeriodCommentIndex = index;
            v.student.grade.comments[index].markingPeriodCommentCodeId = commentCode ? commentCode.markingPeriodCommentCodeId : 0;
            v.student.grade.comments[index].commentDescription = commentCode ? commentCode.commentDescription : '';
            v.student.grade.comments[index].commentCode = commentCode ? commentCode.commentCode : '';
            v.student.grade.comments[index].commentCategory = commentCode ? commentCode.commentCategory : '';
            v.student.grade.comments[index].commentIndicator = commentCode ? commentCode.commentIndicator : '';
            v.student.grade.comments[index].commentLevel = commentCode ? commentCode.commentLevel : '';
            v.student.grade.comments[index].commentType = commentCode ? commentCode.commentType : '';
            v.student.grade.comments[index].schoolTermMarkingPeriodId = schoolTermMarkingPeriodId;
            v.student.grade.comments[index].studentEnrollmentId = v.student.studentEnrollmentId;
            v.student.grade.comments[index].courseSectionId = courseSectionId;
            v.student.grade.comments[index].courseSectionStudentId = v.student.courseSectionStudentId;

            const records = [{
                courseSectionStudentId: v.student.courseSectionStudentId,
                markingPeriodCommentCodeId: v.student.grade.comments[index].markingPeriodCommentCodeId,
                markingPeriodCommentIndex: index,
                conduct: v.student.grade.conduct || null,
                level: v.student.grade.level || null,
                isFinal: v.student.grade.isFinal || false,
            }];
            network.reportCards.saveStudentComment(v.user, schoolTermMarkingPeriodId, records, (err, response) => {
                v.student.grade.comments[index].saving = false;
                v.student.grade.comments[index].dirty = false;
                if (err) {
                    v.student.grade.comments[index].saveStatus = 'Error Saving';
                } else {
                    v.student.grade.comments[index].saveStatus = null;
                }
                v.save();
            });
        },
    },
};
</script>

<style scoped>
div[role="combobox"] {
    flex: 1;
}

div.kt-section {
    margin-bottom: 1em;
}
div.kt-section__desc {
    margin-bottom: 4px;
}
div.kt-section__content {
    margin-bottom: 4px;
}
div.kt-spinner.comment {
    float: right;
    position: relative;
    z-index: 1;
    top: 20px;
    margin-left: 20px;
    left: -30px;
}
.stars-box {
    margin-right: 10px;
    padding-right: 25px;
    border-right: 1px solid lightgrey;
}
.google-box {
    margin-left: 10px;
    padding-left: 25px;
}
.sg-abs-width {
    width: 100px;
}
</style>
