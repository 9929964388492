var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm._m(0)
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", [
      _c("div", { staticClass: "d-flex p-2" }, [
        _c("div", { staticClass: "col-8 align-self-stretch flex-fill" }, [
          _c("div", { staticClass: "d-flex" }, [
            _c("div", { staticClass: "p-2 mr-auto" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "115px", height: "36px" },
              }),
            ]),
          ]),
          _c(
            "div",
            {
              staticClass: "d-flex mt-3",
              staticStyle: { "align-items": "center" },
            },
            [
              _c("div", { staticClass: "p-2 col-3" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: { "margin-bottom": "0" },
                  },
                  [
                    _c("div", { staticClass: "input-group input-group-sm" }, [
                      _c("div", {
                        staticClass: "grey",
                        staticStyle: { width: "100%", height: "38px" },
                      }),
                    ]),
                  ]
                ),
              ]),
              _c("div", { staticClass: "p-2 col-9" }, [
                _c("span", { staticClass: "kt-font-bold font" }, [
                  _c("div", {
                    staticClass: "grey",
                    staticStyle: { width: "80%", height: "20px" },
                  }),
                ]),
              ]),
            ]
          ),
          _c(
            "div",
            { staticClass: "d-flex", staticStyle: { "align-items": "center" } },
            [
              _c("div", { staticClass: "p-2 col-3" }, [
                _c(
                  "div",
                  {
                    staticClass: "form-group",
                    staticStyle: { "margin-bottom": "0px" },
                  },
                  [
                    _c("div", {
                      staticClass: "grey",
                      staticStyle: { width: "100%", height: "38px" },
                    }),
                  ]
                ),
              ]),
              _c("div", { staticClass: "p-2 col-9" }, [
                _c("div", {
                  staticClass: "grey",
                  staticStyle: { width: "80%", height: "20px" },
                }),
              ]),
            ]
          ),
          _c("div", { staticClass: "d-flex mt-4" }, [
            _c("div", { staticClass: "p-2 mr-4 flex-fill" }, [
              _c("div", { staticClass: "form-group" }, [
                _c("div", {
                  staticClass: "grey",
                  staticStyle: { width: "100%", height: "22px" },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("div", {
                  staticClass: "grey",
                  staticStyle: { width: "100%", height: "22px" },
                }),
              ]),
              _c("div", { staticClass: "form-group" }, [
                _c("div", {
                  staticClass: "grey",
                  staticStyle: { width: "100%", height: "22px" },
                }),
              ]),
              _c("div", { staticClass: "form-group validated" }, [
                _c("div", {
                  staticClass: "grey",
                  staticStyle: { width: "100%", height: "20px" },
                }),
              ]),
            ]),
          ]),
        ]),
        _c("div", { staticClass: "col-4 align-self-stretch flex-fill" }, [
          _c("div", { staticClass: "row" }, [
            _c("div", { staticClass: "col-6 p-2" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "130px", height: "55px" },
              }),
            ]),
            _c("div", { staticClass: "col-6 p-2" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "130px", height: "55px" },
              }),
            ]),
            _c("div", { staticClass: "col-6 p-2" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "130px", height: "55px" },
              }),
            ]),
            _c("div", { staticClass: "col-6 p-2" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "130px", height: "55px" },
              }),
            ]),
            _c("div", { staticClass: "col-6 p-2" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "130px", height: "55px" },
              }),
            ]),
            _c("div", { staticClass: "col-6 p-2" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "130px", height: "55px" },
              }),
            ]),
            _c("div", { staticClass: "col-6 p-2" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "130px", height: "55px" },
              }),
            ]),
            _c("div", { staticClass: "col-6 p-2" }, [
              _c("div", {
                staticClass: "grey",
                staticStyle: { width: "130px", height: "55px" },
              }),
            ]),
          ]),
        ]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }