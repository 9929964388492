var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isVisible
        ? _c("StudentGradeEntry", {
            attrs: {
              "passed-student": _vm.passedStudent,
              idx: _vm.idx,
              "save-student": _vm.saveStudent,
              "marking-period": _vm.markingPeriod,
              "grade-template": _vm.gradeTemplate,
              "read-only": _vm.readOnly,
            },
          })
        : _vm._e(),
      !_vm.isVisible
        ? _c("StudentGradeSkeleton", {
            directives: [
              {
                name: "waypoint",
                rawName: "v-waypoint",
                value: {
                  active: true,
                  callback: _vm.onWaypoint,
                  options: _vm.intersectionOptions,
                },
                expression:
                  "{ active: true, callback: onWaypoint, options: intersectionOptions }",
              },
            ],
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }