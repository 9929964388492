<template>
<div
    v-if="isPortfolioReady && areReportCardsReady && markingPeriod"
    :key="`key_${title}_${key}`"
>
    <div
        id="kt_subheader"
        class="kt-subheader kt-grid__item"
    >
        <div class="kt-container kt-container--fluid">
            <div class="kt-subheader__main">
                <SVGIcon
                    :name="icon"
                    :hex-color="$_courseMixins_getColorForCourse(course)"
                    class="mr-3"
                />
                <h3 class="kt-subheader__title">
                    {{ markingPeriod.markingPeriodName }}
                </h3>
                <div class="kt-input-icon kt-input-icon--right kt-subheader__search mt-1">
                    <input
                        v-model="filter"
                        type="text"
                        class="form-control"
                        placeholder="Filter Students..."
                    >
                    <span class="kt-input-icon__icon kt-input-icon__icon--right">
                        <span>
                            <i class="flaticon2-search-1" />
                        </span>
                    </span>
                </div>
            </div>

            <div
                class="kt-subheader__toolbar"
            >
                <div class="kt-subheader__wrapper">
                    <div>
                        <a
                            href="#"
                            class="btn btn-label-brand btn-bold"
                            :class="readOnly ? 'btn-disabled' : ''"
                            @click.stop.prevent="bulk"
                        >
                            Bulk Replace
                        </a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="kt-container kt-container--fluid kt-grid__item kt-grid__item--fluid">
        <div class="kt-portlet">
            <div class="kt-portlet__head d-none">
                <div class="kt-portlet__head-label">
                    <span class="kt-portlet__head-icon pr-0">
                        <SVGIcon
                            hex-color="#0f88ef"
                            class="mr-3"
                            name="reportCards"
                        />
                    </span>
                    <h3 class="kt-portlet__head-title">
                        <span>
                            {{ markingPeriod.markingPeriodName }}
                        </span>
                    </h3>
                </div>
            </div>
            <div class="kt-portlet__body">
                <div
                    v-for="(student, idx) in filteredStudents"
                    :key="student.courseSectionStudentId"
                >
                    <StudentGradeHolder
                        :key="`${key}_${entryKey}`"
                        :idx="idx"
                        :passed-student="student"
                        :save-student="saveStudent"
                        :marking-period="markingPeriod"
                        :grade-template="gradeTemplate"
                        :read-only="readOnly"
                    />
                </div>
            </div>
        </div>
    </div>

    <b-modal
        id="bulk-modal"
        ref="bulk-modal"
        size="xl"
        title="Bulk"
    >
        <template #modal-header="{ close }">
            <h5 class="modal-title">
                Bulk Grading
            </h5>
            <button
                type="button"
                class="close"
                data-dismiss="modal"
                aria-label="Close"
                @click="close()"
            />
        </template>
        <template #default>
            <div class="modal-body">
                <form
                    v-for="(replacement, idx) in replacements"
                    :key="`replacement_${idx}`"
                    class="kt-form p-4"
                >
                    <div class="form-group row mb-0">
                        <div class="col-11">
                            <div class="row">
                                <div class="col-6">
                                    <div class="row">
                                        <div class="col-12">
                                            <div class="form-group">
                                                <label>Find Field:</label>
                                                <select
                                                    v-model="replacement.find.field"
                                                    class="form-control kt-selectpicker"
                                                    @change="ruleChange()"
                                                >
                                                    <option
                                                        v-for="(field, idx) in find.fields"
                                                        :key="'find' + idx + field.value"
                                                        :value="field.value"
                                                        :selected="replacement.find.field == field.value"
                                                    >
                                                        {{ field.name }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div
                                                v-if="replacement.find.field != 'all'"
                                                class="form-group"
                                            >
                                                <label>Find Operator:</label>
                                                <select
                                                    v-model="replacement.find.operator"
                                                    class="form-control kt-selectpicker"
                                                    @change="ruleChange()"
                                                >
                                                    <option
                                                        v-for="(item, idx) in find.operators"
                                                        :key="'operation' + idx + item"
                                                        :value="item"
                                                        :selected="replacement.find.operator == item"
                                                    >
                                                        {{ item }}
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <div
                                                v-if="replacement.find.operator != 'Any value' && replacement.find.field != 'all'"
                                                class="form-group validated"
                                            >
                                                <label>Find Value:</label>
                                                <input
                                                    v-model="replacement.find.value"
                                                    type="text"
                                                    class="form-control"
                                                    :class="{'is-invalid': replacement.find.error}"
                                                    placeholder="Value"
                                                    @change="ruleChange()"
                                                >
                                                <div
                                                    v-if="replacement.find.error"
                                                    class="invalid-feedback"
                                                >
                                                    {{ replacement.find.error }}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-6">
                                    <div class="form-group validated">
                                        <label>Replace Field:</label>
                                        <select
                                            v-model="replacement.replace.field"
                                            class="form-control kt-selectpicker"
                                            @change="ruleChange(); replacement.replace.id = null"
                                        >
                                            <option
                                                v-for="(field, idx) in replace.fields"
                                                :key="'replace' + idx + field.value"
                                                :value="field.value"
                                                :selected="replacement.replace.field == field.value"
                                            >
                                                {{ field.name }}
                                            </option>
                                        </select>
                                    </div>
                                    <div
                                        v-if="replacement.replace.field == 'commentCode'"
                                        class="form-group"
                                    >
                                        <label>Replace First Empty Comment Code With:</label>
                                        <vue-typeahead-bootstrap
                                            :ref="`comment_${idx}`"
                                            :data="commentCodes"
                                            :show-on-focus="true"
                                            :input-class="replacement.replace.error ? 'is-invalid' : ''"
                                            :value="commentVal(replacement)"
                                            :serializer="comment => `${comment.commentCode}: ${comment.commentDescription}`"
                                            placeholder="Comment Code"
                                            @keyup="keyUp($event, `comment_${idx}`, idx)"
                                            @hit="keyUp($event, `comment_${idx}`, idx)"
                                        />
                                        <div
                                            v-if="replacement.replace.error"
                                            class="invalid-feedback"
                                        >
                                            {{ replacement.replace.error }}
                                        </div>
                                    </div>
                                    <div
                                        v-else-if="replacement.replace.field == 'mark'"
                                        class="form-group row"
                                    >
                                        <div v-if="markingPeriod.cumulative" class="col-xl-4">
                                            <label class="kt-radio">
                                                <input
                                                    type="radio"
                                                    :checked="replacement.replace.replacementType == 'reportCardAverage'"
                                                    @click="selectReplacementType('reportCardAverage', replacement)"
                                                > Replace with Report Card Average
                                                <span />
                                            </label>
                                            <p style="padding-left: 30px;">
                                                Use Estimated Cumulative Report Card Average from previous marking periods as Report Card Mark.
                                            </p>
                                        </div>
                                        <div class="col-xl-4">
                                            <label class="kt-radio">
                                                <input
                                                    type="radio"
                                                    :checked="replacement.replace.replacementType == 'markingPeriodAverage'"
                                                    @click="selectReplacementType('markingPeriodAverage', replacement)"
                                                > Replace with MP Average
                                                <span />
                                            </label>
                                            <p style="padding-left: 30px;">
                                                Use MP Floored Average as Report Card Mark. Invalid marks will be skipped.
                                            </p>
                                        </div>
                                        <div class="col-xl-4">
                                            <label class="kt-radio">
                                                <input
                                                    type="radio"
                                                    :checked="replacement.replace.replacementType == 'scaledScore'"
                                                    @click="selectReplacementType('scaledScore', replacement)"
                                                > Replace with Scaled Score
                                                <span />
                                            </label>
                                            <p style="padding-left: 30px;">
                                                Use MP Scaled Average as Report Card Mark. Invalid marks will be skipped.
                                            </p>
                                        </div>
                                        <div class="col-xl-4">
                                            <label class="kt-radio">
                                                <input
                                                    type="radio"
                                                    :checked="replacement.replace.replacementType == 'mark'"
                                                    @click="selectReplacementType('mark', replacement)"
                                                > Replace with Mark
                                                <span />
                                            </label>

                                            <vue-typeahead-bootstrap
                                                :ref="`grade_${idx}`"
                                                :disabled="replacement.replace.replacementType !== 'mark'"
                                                :data="validMarks"
                                                :show-on-focus="true"
                                                :input-class="replacement.replace.error && replacement.replace.replacementType == 'mark' ? 'is-invalid' : ''"
                                                :value="markVal(replacement)"
                                                :serializer="m => `${m.mark}`"
                                                placeholder="Overall Grade"
                                                @keyup="keyUp($event, `grade_${idx}`, idx)"
                                                @hit="keyUp($event, `grade_${idx}`, idx)"
                                            />
                                            <div
                                                v-if="replacement.replace.error"
                                                class="invalid-feedback"
                                            >
                                                {{ replacement.replace.error }}
                                            </div>
                                        </div>
                                    </div>
                                    <!-- <div
                                        v-else
                                        class="form-group row"
                                    >
                                        <div class="col-6">
                                            <label class="kt-radio">
                                                <input
                                                    type="radio"
                                                    :checked="replacement.replace.scaledScore == false && replacement.replace.markingPeriodAverage == false"
                                                    @click="replacement.replace.scaledScore = false; replacement.replace.markingPeriodAverage = false;"
                                                > Replace with Mark
                                                <span />
                                            </label>

                                            <vue-typeahead-bootstrap
                                                :ref="`standardGrade_${idx}`"
                                                :disabled="replacement.replace.scaledScore || replacement.replace.markingPeriodAverage"
                                                :data="validMarks"
                                                :show-on-focus="true"
                                                :input-class="replacement.replace.error && !replacement.replace.scaledScore && !replacement.replace.markingPeriodAverage ? 'is-invalid' : ''"
                                                :value="validMarks.find(m => replacement.replace.id && m.markingPeriodValidMarkId == replacement.replace.id)
                                                    ? `${validMarks.find(m => m.markingPeriodValidMarkId == replacement.replace.id).mark}`
                                                    : ''"
                                                :serializer="m => `${m.mark}`"
                                                placeholder="Overall Grade"
                                                @keyup="keyUp($event, `standardGrade_${idx}`, idx)"
                                                @hit="keyUp($event, `standardGrade_${idx}`, idx)"
                                            />
                                            <div
                                                v-if="replacement.replace.error"
                                                class="invalid-feedback"
                                            >
                                                {{ replacement.replace.error }}
                                            </div>
                                        </div>
                                        <div class="col-6">
                                            <label class="kt-radio">
                                                <input
                                                    type="radio"
                                                    :checked="replacement.replace.scaledScore == true && replacement.replace.markingPeriodAverage == false"
                                                    @click="replacement.replace.scaledScore = true; replacement.replace.markingPeriodAverage = false; replacement.replace.error = null; "
                                                > Replace with Scaled Score
                                                <span />
                                            </label>
                                            <p style="padding-left: 30px;">
                                                Use google average as mark
                                            </p>
                                        </div>
                                    </div> -->
                                </div>
                                <span
                                    v-if="replacement.status"
                                    class="text-muted"
                                >
                                    {{ replacement.status }}
                                </span>
                                <span
                                    v-if="replacement.running"
                                    class="text-muted"
                                >
                                    Running find and replace...
                                </span>
                            </div>
                        </div>
                        <div class="col-1 text-center">
                            <div class="btn-group-vertical">
                                <button
                                    type="button"
                                    title="Move up"
                                    class="btn btn-secondary"
                                    @click="ruleUp(idx)"
                                >
                                    <i class="la la-arrow-up pr-0" />
                                </button>
                                <button
                                    type="button"
                                    title="Move down"
                                    class="btn btn-secondary"
                                    @click="ruleDown(idx)"
                                >
                                    <i class="la la-arrow-down pr-0" />
                                </button>
                                <button
                                    type="button"
                                    title="Delete"
                                    class="btn btn-secondary"
                                    @click="deleteRule(idx)"
                                >
                                    <i class="la la-remove pr-0" />
                                </button>
                                <button
                                    title="Run replace"
                                    type="button"
                                    class="btn btn-secondary"
                                    @click="playRule(replacement)"
                                >
                                    <i class="la la-play pr-0" />
                                </button>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </template>
        <template #modal-footer>
            <div
                class="row"
                style="width: 100%"
            >
                <div class="col-4">
                    <button
                        type="button"
                        class="btn btn-success "
                        @click="addRule()"
                    >
                        <i
                            v-if="!saving"
                            class="fa fa-plus"
                        />
                        Add Rule
                    </button>
                </div>
                <div class="col-8">
                    <div class="pull-right">
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="saving"
                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                            @click="applyBulk()"
                        >
                            <i
                                v-if="!saving"
                                class="la la-play"
                            />
                            {{ saving ? `Please Wait` : `Run All` }}
                        </button>&nbsp;
                        <button
                            type="button"
                            class="btn btn-primary"
                            :disabled="saving"
                            :class="{'kt-spinner kt-spinner--sm kt-spinner--light': saving}"
                            @click="saveBulk()"
                        >
                            <i
                                v-if="!saving"
                                class="fa fa-save"
                            />
                            {{ saving ? `Please Wait` : `Save Rules` }}
                        </button>
                    </div>
                </div>
            </div>
        </template>
    </b-modal>
</div>
</template>

<script>
import Vue from 'vue';
import async from 'async';
import moment from 'moment';
import VueTypeaheadBootstrap from 'vue-typeahead-bootstrap';
import portfolioMixins from '../store/mixins/portfolioMixins';
import reportCardMixins, { populateReportCardData } from '../store/mixins/reportCardMixins';
import courseMixins from '../store/mixins/courseMixins';
import averagingMixins from '../store/mixins/averagingMixins';
import teacherMixins from '../store/mixins/teacherMixins';
import studentMixins from '../store/mixins/studentMixins';
import userMixins from '../store/mixins/userMixins';
import network from '../lib/network';
import StudentGradeHolder from '../components/StudentGradeHolder.vue';
import Types from '../store/Types';

export default Vue.extend({
    name: 'CourseReportCardGrades',
    components: {
        VueTypeaheadBootstrap,
        StudentGradeHolder,
    },
    mixins: [
        portfolioMixins,
        courseMixins,
        averagingMixins,
        teacherMixins,
        reportCardMixins,
        studentMixins,
        userMixins,
    ],
    data() {
        return {
            key: 1,
            bulkKey: 1,
            entryKey: 1,
            saving: false,
            refreshNeeded: false,
            debounce: null,
            filter: '',
            find: {
                fields: [
                    { name: 'All Students', value: 'all' },
                    { name: 'Overall Grade', value: 'mark' },
                    { name: 'Marking Period Average', value: 'average' },
                    { name: 'Course Attendance Percentage', value: 'coursePercentage' },
                    { name: 'Course All Absent Count', value: 'courseAbsent' },
                    { name: 'Course Late Count', value: 'courseLate' },
                    // { name: 'Course Online Absent Count', value: 'courseOnlineAbsent' },
                    // { name: 'Daily Attendance Percentage', value: 'dailyPercentage' },
                    // { name: 'Daily All Absent Count', value: 'dailyAbsent' },
                    // { name: 'Daily Online Absent Count', value: 'dailyOnlineAbsent' },
                ],
                operators: [
                    'Greater than or equals to',
                    'Less than or equals to',
                    'Equals',
                    'Any value',
                ],
            },
            replace: {
                fields: [
                    { name: 'Overall Grade', value: 'mark' },
                    { name: 'Comment Code', value: 'commentCode' },
                ],
            },
            replacements: [],
            students: [],
            schoolTermMarkingPeriodId: null,
        };
    },
    computed: {
        user() {
            return this.$store.state.user;
        },
        schoolEmail() {
            return this.$route.params.schoolEmail;
        },
        extCourseSectionId() {
            return this.$route.params.extCourseSectionId;
        },
        schoolConfiguration() { return null; },
        course() {
            return this.$_courseMixins_getCourseFromRoute();
        },
        gradeTemplate() {
            const { course } = this;
            if (!course) return null;
            return course.gradeTemplate;
        },
        teacher() {
            return this.$_teacherMixins_getTeacherFromRoute();
        },
        markingPeriods() {
            return this.$_reportCardMixins_getMarkingPeriods;
        },
        courseSectionIds() {
            if (!this.course) return null;
            return this.course.courseSectionIds;
        },
        schoolStaffId() {
            if (!this.teacher) return null;
            return this.teacher.schoolStaffId;
        },
        markingPeriod() {
            const { markingPeriods, readOnly } = this;
            const schoolTermMarkingPeriodId = this.$route.params.schoolTermMarkingPeriodId || this.schoolTermMarkingPeriodId;
            if (!schoolTermMarkingPeriodId && !readOnly) return null;
            return markingPeriods.find((m) => ((m.enabled || this.$_userMixins_isSchoolAdmin || readOnly) && m.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId)) || null;
        },
        readOnly() {
            return this.$route.params.readOnly || false;
        },
        title() {
            const route = this.$route;
            return route.meta.title || null;
        },
        icon() {
            const route = this.$route;
            return route.meta.icon || null;
        },
        areReportCardsReady() {
            const { teacher } = this;
            if (!teacher) return false;
            const { schoolStaffId } = teacher;
            const cacheKey = `reportCardData_${schoolStaffId}`;
            return Boolean(this.$store.state.database.cache.find((c) => c.key == cacheKey && c.status == 'cached'));
        },
        commentCodes() {
            const output = this.$store.state.database.reportCards.commentCodes.slice();
            output.unshift({
                commentCode: 'Erase',
                commentDescription: 'All Comments',
                markingPeriodCommentCodeId: 0,
            });
            return output;
        },
        validMarks() {
            const output = this.$store.state.database.reportCards.validMarks.slice();
            output.unshift({
                mark: 'Erase Grade',
                markDescription: '',
                markingPeriodValidMarkId: 0,
                numericEquivalent: null,
                passing: false,
            });
            return output;
        },
        studentAttendance() {
            const { course, $store } = this;
            const { courseSectionAttendance } = $store.state.database;
            const { courseSectionIds } = course;
            return courseSectionAttendance.filter((attendance) => courseSectionIds.indexOf(attendance.courseSectionId) > -1 && !attendance.deleted);
        },
        studentAverages() {
            const { course } = this;
            if (!course) return [];
            return this.$_averagingMixins_getAveragesForCourse(course);
        },
        allMarkingPeriodGrades() {
            const { markingPeriod, course } = this;
            if (!markingPeriod) return [];
            const { cumulative } = markingPeriod;
            if (!cumulative) return [];
            return this.$_reportCardMixins_getGrades(course);
        },
        studentMarkingPeriodGrades() {
            const { markingPeriod, course, allMarkingPeriodGrades } = this;
            if (!markingPeriod) return [];
            const { schoolTermMarkingPeriodId, cumulative } = markingPeriod;
            if (cumulative) return allMarkingPeriodGrades.filter((g) => g.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
            return this.$_reportCardMixins_getGrades(course).filter((g) => g.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
        },
        formattedStudents() {
            const {
                markingPeriods, markingPeriod, course, allMarkingPeriodGrades, studentMarkingPeriodGrades,
                studentAverages, studentAttendance,
            } = this;
            if (!course || !markingPeriod) return [];

            const { courseSectionId } = course;
            const { schoolTermMarkingPeriodId, cumulative } = markingPeriod;
            const markingPeriodStart = moment(markingPeriod.markingPeriodStart, 'YYYY-MM-DD');
            const markingPeriodEnd = moment(markingPeriod.markingPeriodEnd, 'YYYY-MM-DD');
            const students = this.$_studentMixins_getStudentsForCourse(course);

            return students.map((s) => {
                const student = { ...s };
                const grade = studentMarkingPeriodGrades.find((g) => g.courseSectionStudentId == s.courseSectionStudentId);
                student.grade = grade || {};

                student.grade.savingOverall = false;
                student.grade.savingExam = false;
                student.grade.dirtyOverall = false;
                student.grade.dirtyExam = false;
                student.grade.saveStatus = null;
                student.grade.narrativeDirty = false;
                student.grade.narrativeSaveStatus = null;
                student.grade.conductDirty = false;
                student.grade.conductSaveStatus = null;
                student.grade.levelDirty = false;
                student.grade.levelSaveStatus = null;
                student.grade.finalDirty = false;
                student.grade.finalSaveStatus = null;
                student.grade.schoolTermMarkingPeriodId = schoolTermMarkingPeriodId;
                student.grade.courseSectionId = courseSectionId;
                student.grade.courseSectionStudentId = student.courseSectionStudentId;
                student.grade.studentEnrollmentId = student.studentEnrollmentId;
                student.grade.narrativeComment = grade ? grade.narrativeComment : null;
                student.grade.conduct = grade ? grade.conduct : null;
                student.grade.level = grade ? grade.level : null;
                student.grade.isFinal = grade ? grade.isFinal : false;
                student.grade.mark = grade ? grade.mark : null;
                student.grade.examMark = grade ? grade.examMark : null;
                student.grade.markDescription = grade ? grade.markDescription : null;
                student.grade.numericEquivalent = grade ? grade.numericEquivalent : null;
                student.grade.deleted = grade ? grade.deleted : false;

                if (!grade || !grade.comments || !grade.comments.length) {
                    student.grade.comments = new Array(3).fill().map((x) => ({
                        markingPeriodCommentCodeId: 0,
                        saving: false,
                        dirty: false,
                        saveStatus: null,
                    }));
                } else {
                    const { comments } = grade;
                    student.grade.comments = comments.filter((c) => c.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId).map((c) => {
                        const comment = { ...c };
                        comment.saving = false;
                        comment.dirty = false;
                        comment.saveStatus = null;
                        return comment;
                    }).sort((a, b) => a.markingPeriodCommentIndex - b.markingPeriodCommentIndex);
                    if (student.grade.comments.length < 3) {
                        student.grade.comments.push(...new Array(3 - student.grade.comments.length).fill().map((x) => ({
                            markingPeriodCommentCodeId: 0,
                            saving: false,
                            dirty: false,
                            saveStatus: null,
                            commentCode: null,
                            commentDescription: null,
                            commentCategory: null,
                            commentIndicator: null,
                            commentLevel: null,
                            commentType: null,
                            schoolTermMarkingPeriodId,
                            studentEnrollmentId: student.studentEnrollmentId,
                            courseSectionId,
                            courseSectionStudentId: student.courseSectionStudentId,
                        })));
                    }
                }

                const average = studentAverages.find((a) => a.courseSectionStudentId == s.courseSectionStudentId
                    && a.schoolTermMarkingPeriodId == schoolTermMarkingPeriodId);
                student.average = average && average.averageCalculation ? average.averageCalculation : null;

                if (cumulative) {
                    let length = 0;
                    const cumulativeReportCardAverage = allMarkingPeriodGrades
                        .filter((g) => {
                            if (g.courseSectionStudentId != s.courseSectionStudentId) return false;
                            if (!g.numericEquivalent) return false;
                            const mp = markingPeriods.find((m) => m.schoolTermMarkingPeriodId == g.schoolTermMarkingPeriodId);
                            if (!mp) return false;
                            if (mp.markingPeriod >= markingPeriod.markingPeriod) return false;
                            return true;
                        }).reduce((acc, g, idx, array) => {
                            length = array.length;
                            const { numericEquivalent } = g;
                            return acc + (numericEquivalent / 100);
                        }, 0);

                    student.cumulativeReportCardAverage = !Number.isNaN(cumulativeReportCardAverage) && length > 0 ? cumulativeReportCardAverage / length : null;
                }

                student.schoolAttendance = null;
                student.courseAttendance = {
                    total: 0,
                    absent: 0,
                    present: 0,
                    late: 0,
                    percentPresent: 0,
                };

                studentAttendance.forEach((a) => {
                    // limit by mp date range
                    const attendanceDate = moment(a.attendanceDate, 'YYYY-MM-DD');
                    const isInMarkingPeriod = attendanceDate.isBetween(markingPeriodStart, markingPeriodEnd, 'day', '[]');
                    if (a.courseSectionStudentId == s.courseSectionStudentId && isInMarkingPeriod) {
                        if (a.attendanceState == 'Present') {
                            student.courseAttendance.present += 1;
                            student.courseAttendance.total += 1;
                        }
                        if (a.attendanceState == 'Late') {
                            student.courseAttendance.present += 1;
                            student.courseAttendance.late += 1;
                            student.courseAttendance.total += 1;
                        }
                        if (a.attendanceState == 'Absent') {
                            student.courseAttendance.absent += 1;
                            student.courseAttendance.total += 1;
                        }
                        if (a.attendanceState == 'Excused') {
                            student.courseAttendance.present += 1;
                            student.courseAttendance.total += 1;
                        }
                    }
                });

                if (student.courseAttendance.total > 0) {
                    student.courseAttendance.percentPresent = Math.floor((student.courseAttendance.present / student.courseAttendance.total) * 100);
                }

                return student;
            });
        },
        filteredStudents() {
            const { filter, students } = this;
            if (!filter) return students;
            const search = filter.toLowerCase();
            return students.filter((student) => `${student.firstName} ${student.lastName}`.toLowerCase().indexOf(search) > -1);
        },
        submission() {
            if (!this.markingPeriod) return null;
            return this.markingPeriod.submission;
        },
    },
    watch: {
        isPortfolioReady() {
            this.populate();
        },
    },
    mounted() {
        this.populate();
    },
    beforeDestroy() {
        const { students } = this;
        const grades = students.map((s) => ({ ...s.grade })).filter((g) => g && Object.keys(g).length !== 0);
        this.$store.commit(Types.mutations.SET_DB_STUDENT_MARKING_PERIOD_GRADES, grades);
    },
    methods: {
        populate() {
            const v = this;
            if (!v.isPortfolioReady) return;
            const { markingPeriod, course, teacher } = v;
            const { $route, $store } = v;

            if (!course || !teacher) {
                v.showError('Could not get course/teacher');
                return;
            }

            if (!markingPeriod && !v.$_userMixins_isSchoolAdmin) {
                v.$router.push({
                    name: 'TeacherCourseReportCards',
                    params: {
                        schoolEmail: teacher.schoolEmail,
                        extCourseSectionId: course.extCourseSectionId,
                    },
                });
                return;
            }

            v.schoolTermMarkingPeriodId = markingPeriod ? markingPeriod.schoolTermMarkingPeriodId : null;
            populateReportCardData($route, $store, v);
            v.students = v.formattedStudents;
        },
        init() {
            const v = this;
            const { courseSectionIds } = this.course;
            async.auto({
                categories(next) {
                    courseSectionIds.forEach((courseSectionId) => {
                        const stu = v.reportCardStudents.find((item) => {
                            if (!item.average) return false;
                            if (!item.average.markingPeriod) return false;
                            if (!item.average.markingPeriod.categories) return false;
                            return item.courseSectionId == courseSectionId
                                && item.average.markingPeriod.categories.length > 0;
                        });
                        if (stu) {
                            stu.average.markingPeriod.categories.forEach((c) => {
                                if (c.categoryName == 'Uncategorized') return;
                                const exists = v.find.fields.find((f) => f.value == c.categoryName);
                                if (!exists) {
                                    v.find.fields.push({ name: `Category Avg: ${c.categoryName}`, value: c.categoryName });
                                }
                            });
                        }
                    });
                    next();
                },
                standards(next) {
                    courseSectionIds.forEach((courseSectionId) => {
                        const stu = v.reportCardStudents.find((item) => item.courseSectionId == courseSectionId
                            && item.courseStandards.length > 0);
                        if (stu) {
                            stu.courseStandards.forEach((c) => {
                                let desc = c.standardDescription;
                                if (desc.length > 200) {
                                    desc = `${desc.substring(0, 200)}...`;
                                }
                                const exists = v.replace.fields.find((f) => f.value == c.schoolStandardId);
                                if (!exists) {
                                    v.replace.fields.push({ name: `Standard Grade: ${desc}`, value: c.schoolStandardId });
                                }
                            });
                        }
                    });
                    next();
                },
            }, 5, (err) => {
                if (err) v.showError('Error populating');
                v.$root.$on('bv::modal::hide', (bvEvent, modalId) => {
                    if (modalId == 'bulk-modal' && v.refreshNeeded) {
                        v.key += 1;
                    }
                });
            });
        },
        selectReplacementType(replacementType, replacement) {
            const r = replacement;
            r.replace.replacementType = replacementType;
            if (replacementType == 'mark') {
                r.replace.scaledScore = false;
                r.replace.markingPeriodAverage = false;
            }
            if (replacementType == 'scaledScore') {
                r.replace.scaledScore = true;
                r.replace.markingPeriodAverage = false;
            }
            if (replacementType == 'markingPeriodAverage') {
                r.replace.scaledScore = false;
                r.replace.markingPeriodAverage = true;
            }
        },
        markVal(replacement) {
            if (!replacement || !replacement.replace) return null;
            if (replacement.replace.replacementType !== 'mark') return null;
            const markingPeriodValidMarkId = replacement.replace.id;
            if (markingPeriodValidMarkId === 0) return 'Erase Grade';
            if (markingPeriodValidMarkId === null) return null;
            const validMark = this.validMarks.find((m) => m.markingPeriodValidMarkId == markingPeriodValidMarkId);
            if (!validMark) return '';
            return validMark.mark;
        },
        commentVal(replacement) {
            if (!replacement || !replacement.replace) return null;
            if (replacement.replace.id === 0) return 'Erase All Comments';
            if (replacement.replace.id === null) return null;
            const markingPeriodCommentCodeId = replacement.replace.id || null;
            const commentCode = this.commentCodes.find((c) => c.markingPeriodCommentCodeId == markingPeriodCommentCodeId);
            if (!commentCode) return '';
            return `${commentCode.commentCode}: ${commentCode.commentDescription}`;
        },
        download() {
            const v = this;
            network.reportCards.download(v.user, v.submission.classroomSubmissionId, v.schoolStaffId, (err, resp) => {
                if (err) v.showError('Error downloading excel file');
                window.location.href = resp.url;
            });
        },
        saveRules(callback) {
            const v = this;
            if (v.readOnly) return;
            network.reportCards.saveRules(v.user, v.replacements, callback);
        },
        bulk() {
            const v = this;
            if (v.readOnly) return;

            network.reportCards.getRules(v.user, (err, res) => {
                if (err) return v.showError('Error populating rules');
                if (res.rules) {
                    v.replacements = res.rules.replacement.rules.slice();
                } else {
                    v.replacements = [];
                }

                if (v.replacements.length == 0) {
                    v.addRule();
                }
                v.refreshNeeded = false;
                v.replacements = v.replacements.map((r) => {
                    const mutableR = r;
                    mutableR.running = false;
                    mutableR.status = null;
                    mutableR.find.error = null;
                    mutableR.replace.error = null;
                    return mutableR;
                });

                v.$refs['bulk-modal'].show();
            });
        },
        deleteRule(idx) {
            const v = this;
            if (idx == 0 && v.replacements.length == 1) {
                v.replacements = [];
                return v.addRule();
            }
            const items = v.replacements.slice();
            items.splice(idx, 1);
            v.replacements = items.slice();
        },
        ruleUp(idx) {
            const v = this;
            if (idx == 0) return;
            const items = v.replacements.slice();
            const element = items[idx];
            items.splice(idx, 1);
            items.splice(idx - 1, 0, element);
            v.replacements = items.slice();
            v.bulkKey += 1;
        },
        ruleDown(idx) {
            const v = this;
            const items = v.replacements.slice();
            if (idx == items.length) return;
            const element = items[idx];
            items.splice(idx, 1);
            items.splice(idx + 1, 0, element);
            v.replacements = items.slice();
            v.bulkKey += 1;
        },
        ruleChange() {
            const v = this;
            clearTimeout(v.debounce);
            v.debounce = setTimeout(() => {
                v.validate();
            }, 500);
        },

        keyUp(event, field, index) {
            const v = this;
            const ignored = ['Tab', 'ShiftLeft', 'ShiftRight', 'ArrowLeft', 'ArrowRight', 'ArrowUp', 'ArrowDown'];
            const replacement = v.replacements[index];
            if (ignored.indexOf(event.code) > -1) return;

            if (field.startsWith('grade')) {
                const value = v.$refs[field][0].inputValue;
                if (value == '') {
                    v.replacements[index].replace.id = null;
                } else {
                    const mark = v.validMarks.find((m) => `${m.mark}` == value);
                    if (mark) {
                        v.replacements[index].replace.id = mark.markingPeriodValidMarkId;
                    } else {
                        v.replacements[index].replace.id = null;
                    }
                }
            }
            if (field.startsWith('standard')) {
                const value = v.$refs[field][0].inputValue;
                if (value == '') {
                    v.replacements[index].replace.id = null;
                } else {
                    const mark = v.validMarks.find((m) => `${m.mark}` == value);
                    if (mark) {
                        v.replacements[index].replace.id = mark.markingPeriodValidMarkId;
                    } else {
                        v.replacements[index].replace.id = null;
                    }
                }
            }
            if (field.startsWith('comment')) {
                const value = v.$refs[field][0].inputValue;
                if (value == '') {
                    replacement.replace.id = null;
                } else {
                    const commentCode = v.commentCodes.find((comment) => `${comment.commentCode}: ${comment.commentDescription}` == value);
                    if (commentCode) {
                        replacement.replace.id = commentCode.markingPeriodCommentCodeId;
                    } else {
                        replacement.replace.id = null;
                    }
                }
            }
            v.$set(v.replacements, index, replacement);
            v.ruleChange();
        },
        addRule() {
            const v = this;
            v.replacements.push({
                running: false,
                status: null,
                find: {
                    field: 'all',
                    operator: 'Greater than or equals to',
                    value: null,
                    error: null,
                },
                replace: {
                    replacementType: 'markingPeriodAverage',
                    field: 'mark',
                    value: null,
                    scaledScore: false,
                    markingPeriodAverage: true,
                    id: null,
                    error: null,
                },
            });
            v.ruleChange();
        },
        playRule(record, cb) {
            let callback = cb;
            const r = record;
            const v = this;

            if (!callback) callback = function (err) { if (err) v.showError('Error running'); };
            const hasErrors = r.find.error || r.find.replace;
            if (hasErrors) {
                return v.showError('Please correct the errors in red');
            }

            if (r.running) return;
            r.running = true;
            r.status = null;

            const indexesToReplace = v.findMatches(r);

            const updates = [];
            indexesToReplace.forEach((idx) => {
                const student = v.students[idx];
                if (r.replace.field == 'commentCode') {
                    if (r.replace.id == 0) {
                        // wipe all comments
                        updates.push({
                            courseSectionStudentId: student.courseSectionStudentId,
                            markingPeriodCommentCodeId: 0,
                            markingPeriodCommentIndex: 0,
                            idx,
                        });
                        updates.push({
                            courseSectionStudentId: student.courseSectionStudentId,
                            markingPeriodCommentCodeId: 0,
                            markingPeriodCommentIndex: 1,
                            idx,
                        });
                        updates.push({
                            courseSectionStudentId: student.courseSectionStudentId,
                            markingPeriodCommentCodeId: 0,
                            markingPeriodCommentIndex: 2,
                            idx,
                        });
                    } else if (!student.grade.comments[0].markingPeriodCommentCodeId) {
                        updates.push({
                            courseSectionStudentId: student.courseSectionStudentId,
                            markingPeriodCommentCodeId: r.replace.id,
                            markingPeriodCommentIndex: 0,
                            idx,
                        });
                    } else if (!student.grade.comments[1].markingPeriodCommentCodeId) {
                        updates.push({
                            courseSectionStudentId: student.courseSectionStudentId,
                            markingPeriodCommentCodeId: r.replace.id,
                            markingPeriodCommentIndex: 1,
                            idx,
                        });
                    } else if (!student.grade.comments[2].markingPeriodCommentCodeId) {
                        updates.push({
                            courseSectionStudentId: student.courseSectionStudentId,
                            markingPeriodCommentCodeId: r.replace.id,
                            markingPeriodCommentIndex: 2,
                            idx,
                        });
                    }
                } else if (r.replace.field == 'mark') {
                    const update = {
                        courseSectionStudentId: student.courseSectionStudentId,
                        markingPeriodValidMarkId: null,
                        markingPeriodValidExamMarkId: student.grade.markingPeriodValidExamMarkId || null,
                        narrativeComment: student.grade.narrativeComment || null,
                        conduct: student.grade.conduct || null,
                        level: student.grade.level || null,
                        isFinal: student.grade.isFinal || null,
                        idx,
                    };
                    const { validMarks } = v;
                    if (r.replace.replacementType == 'scaledScore' || r.replace.replacementType == 'markingPeriodAverage' || r.replace.replacementType == 'reportCardAverage') {
                        if (student.average && student.average.markingPeriod && student.average.markingPeriod.scaled) {
                            const { mark } = student.average.markingPeriod.scaled;
                            const average = Math.floor(student.average.markingPeriod.average * 100);

                            if (r.replace.replacementType == 'scaledScore') {
                                const validMark = validMarks.find((m) => m.mark == mark);
                                if (validMark) update.markingPeriodValidMarkId = validMark.markingPeriodValidMarkId;
                            }

                            if (r.replace.replacementType == 'markingPeriodAverage') {
                                const validMark = validMarks.find((m) => m.mark == String(average));
                                if (validMark) update.markingPeriodValidMarkId = validMark.markingPeriodValidMarkId;
                            }

                            if (r.replace.replacementType == 'reportCardAverage' && !Number.isNaN(student.cumulativeReportCardAverage)) {
                                const cumulativeAverage = Math.floor(student.cumulativeReportCardAverage * 100);
                                const validMark = validMarks.find((m) => m.mark == String(cumulativeAverage));
                                if (validMark) update.markingPeriodValidMarkId = validMark.markingPeriodValidMarkId;
                            }
                        }
                    }
                    if (r.replace.replacementType == 'mark') {
                        update.markingPeriodValidMarkId = r.replace.id;
                    }

                    if (update.markingPeriodValidMarkId !== null) {
                        updates.push(update);
                    }
                } else if (r.replace.scaledScore) {
                    if (student.average && student.average.markingPeriod && student.average.markingPeriod.scaled) {
                        // not skipping
                    } else {
                        // skipping
                        return;
                    }
                    updates.push({
                        courseSectionStudentId: student.courseSectionStudentId,
                        markingPeriodValidMarkId: student.average.markingPeriod.scaled.markingPeriodValidMarkId,
                        schoolStandardId: r.replace.field,
                        idx,
                    });
                } else {
                    updates.push({
                        courseSectionStudentId: student.courseSectionStudentId,
                        markingPeriodValidMarkId: r.replace.id,
                        schoolStandardId: r.replace.field,
                        idx,
                    });
                }
            });

            if (updates.length == 0) {
                r.status = '0 records modified.';
                r.running = false;
                return callback();
            }

            const gradeChunks = v.chunk(updates.filter((x) => x.hasOwnProperty('markingPeriodValidMarkId') && r.hasOwnProperty('schoolStandardId') == false), 50);
            const commentChunks = v.chunk(updates.filter((x) => x.hasOwnProperty('markingPeriodCommentCodeId')), 50);
            const standardGradeChunks = v.chunk(updates.filter((x) => x.hasOwnProperty('schoolStandardId')), 50);
            const chunks = gradeChunks.concat(commentChunks, standardGradeChunks);

            async.eachSeries(chunks, (records, next) => {
                if (records[0].hasOwnProperty('schoolStandardId')) {
                    network.reportCards.saveStudentStandardGrade(v.user, v.markingPeriod.schoolTermMarkingPeriodId, records, (err) => {
                        if (err) return next(err);
                        // update vue state
                        records.forEach((row) => {
                            const mark = v.validMarks.find((m) => m.markingPeriodValidMarkId == row.markingPeriodValidMarkId);
                            const student = v.students[row.idx];
                            const standard = student.courseStandards.find((s) => s.schoolStandardId == row.schoolStandardId);

                            standard.grade.mark = row.markingPeriodValidMarkId == 0 ? '' : mark.mark;
                            standard.grade.markDescription = mark.markDescription;
                            standard.grade.markingPeriodValidMarkId = row.markingPeriodValidMarkId;
                            v.saveStudent(student, row.idx);
                        });
                        next(err);
                    });
                } else if (records[0].hasOwnProperty('markingPeriodValidMarkId')) {
                    network.reportCards.saveStudentGrade(v.user, v.markingPeriod.schoolTermMarkingPeriodId, records, (err) => {
                        if (err) return next(err);
                        // update vue state
                        records.forEach((row) => {
                            const mark = v.validMarks.find((m) => m.markingPeriodValidMarkId == row.markingPeriodValidMarkId);
                            if (!mark) return;
                            const student = v.students[row.idx];
                            student.grade.mark = row.markingPeriodValidMarkId == 0 ? '' : mark.mark;
                            student.grade.markDescription = mark.markDescription;
                            student.grade.passing = mark.passing;
                            student.grade.markingPeriodValidMarkId = row.markingPeriodValidMarkId;
                            v.saveStudent(student, row.idx);
                        });
                        next(err);
                    });
                } else if (records[0].hasOwnProperty('markingPeriodCommentCodeId')) {
                    network.reportCards.saveStudentComment(v.user, v.markingPeriod.schoolTermMarkingPeriodId, records, (err) => {
                        if (err) return next(err);
                        // update vue state
                        records.forEach((row) => {
                            const comment = v.commentCodes.find((c) => c.markingPeriodCommentCodeId == row.markingPeriodCommentCodeId);
                            const student = v.students[row.idx];

                            student.grade.comments[row.markingPeriodCommentIndex].markingPeriodCommentCodeId = row.markingPeriodCommentCodeId;
                            student.grade.comments[row.markingPeriodCommentIndex].commentCode = row.markingPeriodCommentCodeId == 0 ? '' : comment.commentCode;
                            student.grade.comments[row.markingPeriodCommentIndex].commentDescription = row.markingPeriodCommentCodeId == 0 ? '' : comment.commentDescription;
                            student.grade.comments[row.markingPeriodCommentIndex].commentCategory = row.markingPeriodCommentCodeId == 0 ? '' : comment.commentCategory;
                            student.grade.comments[row.markingPeriodCommentIndex].commentIndicator = row.markingPeriodCommentCodeId == 0 ? '' : comment.commentIndicator;
                            student.grade.comments[row.markingPeriodCommentIndex].commentLevel = row.markingPeriodCommentCodeId == 0 ? '' : comment.commentLevel;
                            student.grade.comments[row.markingPeriodCommentIndex].commentType = row.markingPeriodCommentCodeId == 0 ? '' : comment.commentType;

                            v.saveStudent(student, row.idx);
                        });
                        next(err);
                    });
                }
            }, (err) => {
                v.entryKey += 1;
                r.status = `${updates.length} records modified.`;
                r.running = false;
                v.refreshNeeded = true;
                callback(err);
            });
        },
        saveStudent(student, idx) {
            const v = this;
            v.$set(v.students, idx, student);
        },
        isNumeric(n) {
            // eslint-disable-next-line no-restricted-globals
            return !isNaN(parseFloat(n)) && isFinite(n);
        },
        chunk(arr, len) {
            const chunks = []; let i = 0; const
                n = arr.length;
            while (i < n) {
                chunks.push(arr.slice(i, i += len));
            }
            return chunks;
        },
        saveBulk() {
            const v = this;
            if (v.saving) return;
            v.saving = true;
            const hasErrors = v.replacements.find((x) => x.find.error || x.replace.error);
            if (hasErrors) {
                v.saving = false;
                return v.showError('Please correct the errors in red');
            }
            v.saveRules((err) => {
                if (err) return v.showError('Error saving rules');
                v.saving = false;
            });
        },
        applyBulk() {
            const v = this;
            if (v.saving) return;
            v.saving = true;
            const hasErrors = v.replacements.find((x) => x.find.error || x.replace.error);
            if (hasErrors) {
                v.saving = false;
                return v.showError('Please correct the errors in red');
            }

            async.eachSeries(v.replacements, (replacement, next) => {
                v.playRule(replacement, next);
            }, (err) => {
                if (err) {
                    console.error(err);
                    v.showError('Error applying replacement');
                }
                v.saving = false;
            });
        },
        validate() {
            const v = this;
            v.replacements.forEach((replacement, idx) => {
                const x = replacement;
                x.find.error = null;
                x.replace.error = null;
                if (x.find.operator !== 'Any value' && x.find.field !== 'all') {
                    if (x.find.value == '') {
                        x.find.error = 'Enter a valid find value';
                    }
                }

                if (x.find.field.startsWith('course') || x.find.field.startsWith('daily')) {
                    if (!v.isNumeric(x.find.value)) {
                        x.find.error = 'Enter a numerical value';
                    }
                }

                if (x.replace.field == 'commentCode') {
                    const value = v.$refs[`comment_${idx}`][0].inputValue;
                    if (value == '') {
                        x.replace.id = null;
                    } else if (value == 'Erase All Comments') {
                        x.replace.id = 0;
                    } else {
                        const commentCode = v.commentCodes.find((comment) => `${comment.commentCode}: ${comment.commentDescription}` == value);
                        if (commentCode) {
                            x.replace.id = commentCode.markingPeriodCommentCodeId;
                        } else {
                            x.replace.id = null;
                        }
                    }

                    if (x.replace.id === null) {
                        x.replace.error = 'Select a valid replacement comment code';
                    }
                } else if (x.replace.field == 'mark') {
                    const value = v.$refs[`grade_${idx}`][0].inputValue;
                    if (value == '') {
                        x.replace.id = null;
                    } else if (value == 'Erase Grade') {
                        x.replace.id = 0;
                    } else {
                        const mark = v.validMarks.find((m) => `${m.mark}` == value);
                        if (mark) {
                            x.replace.id = mark.markingPeriodValidMarkId;
                        } else {
                            x.replace.id = null;
                        }
                    }
                    if (x.replace.id === null && x.replace.scaledScore == false && x.replace.markingPeriodAverage == false) {
                        x.replace.error = 'Select a valid replacement mark';
                    }
                } else {
                    // standard
                    x.replace.field = x.replace.field.toString();
                    const value = v.$refs[`standardGrade_${idx}`][0].inputValue;
                    if (value == '') {
                        x.replace.id = null;
                    } else {
                        const mark = v.validMarks.find((m) => `${m.mark}` == value);
                        if (mark) {
                            x.replace.id = mark.markingPeriodValidMarkId;
                        } else {
                            x.replace.id = null;
                        }
                    }
                    if (x.replace.id === null && x.replace.scaledScore == false && x.replace.markingPeriodAverage == false) {
                        x.replace.error = 'Select a valid replacement mark';
                    }
                }

                v.$set(v.replacements, idx, x);
            });
        },
        findMatches(r) {
            const v = this;
            const { students } = this;
            const toReplace = [];
            const findTitle = v.find.fields.find((f) => f.value == r.find.field).name;
            students.forEach((student, idx) => {
                if (r.find.field === 'all') {
                    toReplace.push(idx);
                }

                if (r.find.field === 'mark') {
                    if (!student.grade.mark) return;
                    if (r.find.operator === 'Greater than or equals to') {
                        if (v.isNumeric(student.grade.mark) && parseInt(student.grade.mark, 10) >= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Less than or equals to') {
                        if (v.isNumeric(student.grade.mark) && parseInt(student.grade.mark, 10) <= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Equals') {
                        if (r.find.value.toString() === student.grade.mark.toString()) {
                            toReplace.push(idx);
                        }
                    } else {
                        toReplace.push(idx);
                    }
                }

                if (r.find.field.startsWith('course')) {
                    if (!student.courseAttendance) return;

                    let f;

                    if (r.find.field === 'coursePercentage') f = student.courseAttendance.percentPresent;
                    if (r.find.field === 'courseAbsent') {
                        f = student.courseAttendance.absent + student.courseAttendance.onlineAbsent;
                    }
                    if (r.find.field === 'courseOnlineAbsent') f = student.courseAttendance.onlineAbsent;
                    if (r.find.field === 'courseLate') f = student.courseAttendance.late;

                    if (r.find.operator === 'Greater than or equals to') {
                        if (f >= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Less than or equals to') {
                        if (f <= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Equals') {
                        if (r.find.value.toString() === f.toString()) {
                            toReplace.push(idx);
                        }
                    } else {
                        toReplace.push(idx);
                    }
                }

                if (r.find.field.startsWith('daily')) {
                    if (!student.schoolAttendance) return;

                    let f;
                    if (r.find.field === 'dailyPercentage') f = student.schoolAttendance.percentPresent;
                    if (r.find.field === 'dailyOnlineAbsent') f = student.schoolAttendance.onlineAbsent;
                    if (r.find.field === 'dailyAbsent') {
                        f = (student.schoolAttendance.absent + student.schoolAttendance.onlineAbsent);
                    }

                    if (r.find.operator === 'Greater than or equals to') {
                        if (f >= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Less than or equals to') {
                        if (f <= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Equals') {
                        if (r.find.value.toString() == f.toString()) {
                            toReplace.push(idx);
                        }
                    } else {
                        toReplace.push(idx);
                    }
                }

                if (student.average && student.average.markingPeriod && student.average.markingPeriod.average) {
                    // run this
                } else {
                    return;
                }
                if (r.find.field === 'average') {
                    const average = (student.average.markingPeriod.average * 100);
                    if (r.find.operator === 'Greater than or equals to') {
                        if (average >= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Less than or equals to') {
                        if (average <= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Equals') {
                        if (Math.floor(average).toString() === student.grade.mark.toString()) {
                            toReplace.push(idx);
                        }
                    } else {
                        toReplace.push(idx);
                    }
                }

                if (findTitle.startsWith('Category Avg')) {
                    const category = student.average.markingPeriod.categories.find((c) => c.categoryName == r.find.field);

                    if (!category) return;
                    const average = (category.percentEarned * 100);

                    if (r.find.operator === 'Greater than or equals to') {
                        if (average >= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Less than or equals to') {
                        if (average <= parseInt(r.find.value, 10)) {
                            toReplace.push(idx);
                        }
                    } else if (r.find.operator === 'Equals') {
                        if (Math.floor(average).toString() === student.grade.mark.toString()) {
                            toReplace.push(idx);
                        }
                    } else {
                        toReplace.push(idx);
                    }
                }
            });
            return toReplace;
        },
    },
});
</script>

<style scoped>
.kt-section {
    margin: 0;
}
.kt-section__desc {
    margin: 0;
}

#bulk-modal .modal-body form.kt-form:nth-of-type(even) {
    background: rgb(247, 248, 250);
}

#bulk-modal .modal-body {
    height: 75vh;
    overflow-y: auto;
}

.btn-disabled {
    pointer-events: none;
    color: gray;
}
</style>
