<template>
<b-modal
    ref="comment-code-modal"
    size="lg"
    variant="primary"
    static
    centered
    hide-footer
>
    <template #modal-header="{ close }">
        <h5 class="modal-title">
            Comment Codes
        </h5>
        <button
            type="button"
            class="close"
            data-dismiss="modal"
            aria-label="Close"
            @click="close()"
        />
    </template>
    <template #default>
        <div
            class="modal-body"
            style="padding: 0px 10px; overflow-x: hidden"
        >
            <div
                class="row pb-3"
                style="position: sticky; top: 0; background: white; z-index: 1"
            >
                <div class="col-12">
                    <div class="kt-input-icon kt-input-icon--left">
                        <input
                            v-model="filter"
                            type="text"
                            class="form-control"
                            placeholder="Filter..."
                        >
                        <span class="kt-input-icon__icon kt-input-icon__icon--left">
                            <span>
                                <i class="la la-search" />
                            </span>
                        </span>
                    </div>
                    <span class="text-muted">Select a comment below to apply the mark to the student</span>
                </div>
            </div>

            <div
                v-for="(comments, header) in commentCodes"
                :key="'header'+header"
                class="row"
            >
                <p class="col-12 kt-font-bold">
                    {{ header }}
                </p>
                <div
                    v-for="comment in comments"
                    :key="'comm_'+comment.commentCode"
                    class="col-4"
                >
                    <div
                        class="pb-2 pt-2"
                        style="cursor: pointer"
                        @click="chooseComment(comment)"
                    >
                        <code style="color: #0571f7">{{ comment.commentCode }}</code> -
                        <span>{{ comment.commentDescription }}</span>
                    </div>
                </div>
            </div>
        </div>
    </template>
</b-modal>
</template>

<script>
import { mapState } from 'vuex';

export default {
    name: 'CommentCodesModal',
    props: {
        applyComment: {
            type: Function,
            required: true,
        },
        index: {
            type: Number,
            required: true,
        },
    },
    data() {
        return {
            filter: '',
        };
    },
    computed: mapState({
        commentCodes(state) {
            const v = this;
            const output = {};
            const filter = v.filter ? v.filter.toLowerCase() : '';
            state.database.reportCards.commentCodes.forEach((c) => {
                let skip = false;
                if (filter) {
                    skip = c.commentDescription.toLowerCase().indexOf(filter) == -1
                        && c.commentType.toLowerCase().indexOf(filter) == -1
                        && c.commentCategory.toLowerCase().indexOf(filter) == -1;
                }

                if (skip) return;
                const header = `${c.commentCategory}: ${c.commentType}`;
                if (!output.hasOwnProperty(header)) {
                    output[header] = [];
                }
                output[header].push(c);
            });
            return output;
        },
    }),
    methods: {
        open() {
            const v = this;
            v.load();
            v.$refs['comment-code-modal'].show();
        },
        load() {
            const v = this;
            v.filter = '';
        },
        chooseComment(comment) {
            const v = this;
            v.applyComment(comment, v.index);
            v.$refs['comment-code-modal'].hide();
        },
    },
};
</script>

<style scoped>
.kt-section__desc {
	margin-bottom: 0.4rem;
}
.modal-dialog {
	overflow-y: initial !important;
}
.modal-body {
	max-height: 75vh;
	overflow-y: auto;
}
</style>
