var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("b-modal", {
    ref: "comment-code-modal",
    attrs: {
      size: "lg",
      variant: "primary",
      static: "",
      centered: "",
      "hide-footer": "",
    },
    scopedSlots: _vm._u([
      {
        key: "modal-header",
        fn: function ({ close }) {
          return [
            _c("h5", { staticClass: "modal-title" }, [
              _vm._v(" Comment Codes "),
            ]),
            _c("button", {
              staticClass: "close",
              attrs: {
                type: "button",
                "data-dismiss": "modal",
                "aria-label": "Close",
              },
              on: {
                click: function ($event) {
                  return close()
                },
              },
            }),
          ]
        },
      },
      {
        key: "default",
        fn: function () {
          return [
            _c(
              "div",
              {
                staticClass: "modal-body",
                staticStyle: { padding: "0px 10px", "overflow-x": "hidden" },
              },
              [
                _c(
                  "div",
                  {
                    staticClass: "row pb-3",
                    staticStyle: {
                      position: "sticky",
                      top: "0",
                      background: "white",
                      "z-index": "1",
                    },
                  },
                  [
                    _c("div", { staticClass: "col-12" }, [
                      _c(
                        "div",
                        { staticClass: "kt-input-icon kt-input-icon--left" },
                        [
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model",
                                value: _vm.filter,
                                expression: "filter",
                              },
                            ],
                            staticClass: "form-control",
                            attrs: { type: "text", placeholder: "Filter..." },
                            domProps: { value: _vm.filter },
                            on: {
                              input: function ($event) {
                                if ($event.target.composing) return
                                _vm.filter = $event.target.value
                              },
                            },
                          }),
                          _c(
                            "span",
                            {
                              staticClass:
                                "kt-input-icon__icon kt-input-icon__icon--left",
                            },
                            [
                              _c("span", [
                                _c("i", { staticClass: "la la-search" }),
                              ]),
                            ]
                          ),
                        ]
                      ),
                      _c("span", { staticClass: "text-muted" }, [
                        _vm._v(
                          "Select a comment below to apply the mark to the student"
                        ),
                      ]),
                    ]),
                  ]
                ),
                _vm._l(_vm.commentCodes, function (comments, header) {
                  return _c(
                    "div",
                    { key: "header" + header, staticClass: "row" },
                    [
                      _c("p", { staticClass: "col-12 kt-font-bold" }, [
                        _vm._v(" " + _vm._s(header) + " "),
                      ]),
                      _vm._l(comments, function (comment) {
                        return _c(
                          "div",
                          {
                            key: "comm_" + comment.commentCode,
                            staticClass: "col-4",
                          },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "pb-2 pt-2",
                                staticStyle: { cursor: "pointer" },
                                on: {
                                  click: function ($event) {
                                    return _vm.chooseComment(comment)
                                  },
                                },
                              },
                              [
                                _c(
                                  "code",
                                  { staticStyle: { color: "#0571f7" } },
                                  [_vm._v(_vm._s(comment.commentCode))]
                                ),
                                _vm._v(" - "),
                                _c("span", [
                                  _vm._v(_vm._s(comment.commentDescription)),
                                ]),
                              ]
                            ),
                          ]
                        )
                      }),
                    ],
                    2
                  )
                }),
              ],
              2
            ),
          ]
        },
        proxy: true,
      },
    ]),
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }