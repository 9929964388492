<template>
<div>
    <div class="d-flex p-2">
        <div class="col-8 align-self-stretch flex-fill">
            <div class="d-flex">
                <div class="p-2 mr-auto">
                    <!-- Name -->
                    <div
                        class="grey"
                        style="width: 115px; height: 36px"
                    />
                </div>
            </div>
            <div
                class="d-flex mt-3"
                style="align-items: center;"
            >
                <div class="p-2 col-3">
                    <!-- Overall Grade -->
                    <div
                        class="form-group"
                        style="margin-bottom: 0"
                    >
                        <div class="input-group input-group-sm">
                            <div
                                class="grey"
                                style="width: 100%; height: 38px"
                            />
                        </div>
                    </div>
                </div>
                <div class="p-2 col-9">
                    <!-- Overall Title -->
                    <span class="kt-font-bold font">
                        <div
                            class="grey"
                            style="width: 80%; height: 20px"
                        />
                    </span>
                </div>
            </div>

            <div
                class="d-flex"
                style="align-items: center;"
            >
                <div class="p-2 col-3">
                    <!-- Standard Grade -->
                    <div
                        class="form-group"
                        style="margin-bottom: 0px"
                    >
                        <div
                            class="grey"
                            style="width: 100%; height: 38px"
                        />
                    </div>
                </div>
                <div class="p-2 col-9">
                    <!-- Standard Title -->
                    <div
                        class="grey"
                        style="width: 80%; height: 20px"
                    />
                </div>
            </div>

            <div class="d-flex mt-4">
                <div class="p-2 mr-4 flex-fill">
                    <div class="form-group">
                        <!-- Comment1 -->
                        <div
                            class="grey"
                            style="width: 100%; height:22px"
                        />
                    </div>
                    <div class="form-group">
                        <!-- Comment2 -->
                        <div
                            class="grey"
                            style="width: 100%; height:22px"
                        />
                    </div>
                    <div class="form-group">
                        <!-- Comment3 -->
                        <div
                            class="grey"
                            style="width: 100%; height:22px"
                        />
                    </div>
                    <div class="form-group validated">
                        <!-- Narrative -->
                        <div
                            class="grey"
                            style="width: 100%; height:20px"
                        />
                    </div>
                </div>
            </div>
        </div>
        <div class="col-4 align-self-stretch flex-fill">
            <div class="row">
                <div class="col-6 p-2">
                    <!-- Averages -->
                    <div
                        class="grey"
                        style="width: 130px; height:55px"
                    />
                </div>
                <div class="col-6 p-2">
                    <div
                        class="grey"
                        style="width: 130px; height:55px"
                    />
                </div>
                <div class="col-6 p-2">
                    <div
                        class="grey"
                        style="width: 130px; height:55px"
                    />
                </div>
                <div class="col-6 p-2">
                    <div
                        class="grey"
                        style="width: 130px; height:55px"
                    />
                </div>
                <div class="col-6 p-2">
                    <div
                        class="grey"
                        style="width: 130px; height:55px"
                    />
                </div>
                <div class="col-6 p-2">
                    <div
                        class="grey"
                        style="width: 130px; height:55px"
                    />
                </div>
                <div class="col-6 p-2">
                    <div
                        class="grey"
                        style="width: 130px; height:55px"
                    />
                </div>
                <div class="col-6 p-2">
                    <div
                        class="grey"
                        style="width: 130px; height:55px"
                    />
                </div>
            </div>
        </div>
    </div>
</div>
</template>

<script>
export default {
    name: 'StudentGradeSkeleton',
};
</script>

<style>

.grey {
	position: relative;
	background-color: #e2e2e2;
}

/* The moving element */
.grey::after {
	display: block;
	content: "";
	position: absolute;
	width: 100%;
	height: 100%;
	transform: translateX(-100%);
	background: -webkit-gradient(linear, left top,
				right top, from(transparent),
				color-stop(rgba(255, 255, 255, 0.2)),
				to(transparent));

	background: linear-gradient(90deg, transparent,
			rgba(255, 255, 255, 0.2), transparent);

	/* Adding animation */
	animation: loading 0.8s infinite;
}

/* Loading Animation */
@keyframes loading {
	100% {
		transform: translateX(100%);
	}
}
</style>
